import React, {useContext, useState, useRef, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {ConstLink} from "../ConstLink";
import Select from 'react-select';
import BorderedComponent from '../Components/BorderedComponent';
import CurrencyInput from 'react-currency-input-field';
import QRCode from "react-qr-code"
import QRCodeStyling from "qr-code-styling";
// import UploadDropzone from '../../../Components/UploadDropzone';  No longer Used
import Upload from "../Components/Dropzone/upload/Upload";
import {Container, Row, Col } from 'react-bootstrap';

// import FileUpload from '../../../Components/FileUpload';


import QRLogo from '../Images/QRLogo.png';
import ModuleTable from "../Components/ModuleTable";
export default function TemplateSteps() {
    const [studentList, setStudentList] = useState([]); //This is for the accordion Function as well
    const navigate = useNavigate();
    const { setQuickMenu, isLoggedIn, setStepButtons } = useContext(ConstLink);
    const [pageName, setPageName] = useState('');
    const [QRCodeVal, setQRCodeVal] = useState("QR Code Value will link to class details");
    const [activeTab, setActiveTab] = useState('Tab 1');
    const [url, setUrl] = useState("QR Code Value will link to class details");
    // const [QRCode, QRCode2] = useRef(null);
    const QRCode = useRef(null)
    const QRCode2 = useRef(null)
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(formData);

        switch (activeTab) {
            case 'Tab 1':
                setActiveTab('Tab 2');
                break;
            case 'Tab 2':
                setActiveTab('Tab 3');
                break;
            case 'Tab 3':
                setActiveTab('Tab 4');
                break;
            case 'Tab 4':
                setActiveTab('Tab 5');
                break;
            case 'Tab 5':
                setActiveTab('Tab 6');
                break;
            case 'Tab 6':
                setActiveTab('Tab 7');
                break;
            case 'Tab 7':
                setActiveTab('Tab 8');
                break;
            default:
                break;
        }
    };
    const handleChangeTab = (tabIndex) => {
        handleTabClick(tabIndex)
    }


    if ( !isLoggedIn ) {
        navigate('/login')
    }
    function cloneQRCode(qrCode) {
        const config = JSON.parse(JSON.stringify(qrCode._options));
        return new QRCodeStyling(config);
    }

    useEffect(() => {
        updateQuickMenu();
        const pathArray = window.location.pathname.split('/');
        const pageParam = pathArray[pathArray.length - 1];
        setPageName(pageParam || 'Default Page');
        qrCode.append(QRCode.current);

        const qrCodeClone = cloneQRCode(qrCode);
        qrCodeClone.append(QRCode2.current);
    }, []);

    useEffect(() => {
        qrCode.update({
            data: url
        });
    }, [url]);
    const onUrlChange = (event) => {
        event.preventDefault();
        setUrl(event.target.value);
    };
    const updateQuickMenu = () => {
        setQuickMenu(
            ["Item 1", 'route'],
            ["Item 2", 'route'],
            ["Item 3", 'route'],
            ["Item 4", 'route'],
            ["Item 5", 'route'],
            ["Item 6", 'route'], );
    };

    // Helper function to get a formatted date in the format "YYYY-MM-DD"
    const getFormattedDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [cboBox1, setCboBox1] = useState('');

    const options = [
        { value: 'option1', label: 'Option 1' },
        { value: 'option2', label: 'Option 2' },
        { value: 'option3', label: 'Option 3' },
        // Add more options as needed as this is your dropdown choices and would normally be pulled from the DBase
    ];

    // const handleCombo1Change = (selectedOption) => {
    //     console.log(selectedOption); // Do something with the selected option
    // };

    useEffect(() => {
        const tabContents = document.getElementsByClassName('tabcontent');

        for (let i = 0; i < tabContents.length; i++) {
            if (tabContents[i].id === activeTab) {
                tabContents[i].style.display = 'block';
            } else {
                tabContents[i].style.display = 'none';
            }
        }
    }, [activeTab]);

    const [formData, setFormData] = useState({
        textInputVal: '',
        dropdownVal: '',
        startDateVal: getFormattedDate(new Date()),
        finishDateVal: getFormattedDate(new Date()),
        startTimeVal: '08:00',
        finishTimeVal: '15:00',
        classMinimumVal: '',
        classMaximumVal: '',
        classCostVal: '',
        isPrivateVal: 'checked',
        venueVal: '',
        borderedComponentVal: '',
    });

    // These are all of the items for the accordion function

    // Function to fetch the data from the database and populate the accordionData state
    const fetchStudentList = async () => {
        // Replace this with your actual database call logic
        // For example:
        // const response = await fetch('your-api-endpoint');
        // const data = await response.json();
        // setAccordionData(data);
        // For now, let's use a static example data:

        const exampleData = [
            {
                heading: 'Student 1',
                mobile: '123-456-7890',
                billing: 'Student',
                usi: '12345',
                competent: 'Uncehcked',
                actions: 'Approve',
                extraLineItems: [
                    { code: 'CODE-1', name: 'Item 1', evidence: 'Evidence 1' },
                    { code: 'CODE-2', name: 'Item 2', evidence: 'Evidence 2' },
                ],
            },                {
                heading: 'Student 2',
                mobile: '123-456-7890',
                billing: 'Employer',
                usi: '12345',
                competent: 'Uncehcked',
                actions: 'Approve',
                extraLineItems: [
                    { code: 'CODE-1', name: 'Item 1', evidence: 'Evidence 1' },
                    { code: 'CODE-2', name: 'Item 2', evidence: 'Evidence 2' },
                ],
            },                {
                heading: 'Student 3',
                mobile: '123-456-7890',
                billing: 'Student',
                usi: '12345',
                competent: 'Uncehcked',
                actions: 'Approve',
                extraLineItems: [
                    { code: 'CODE-1', name: 'Item 1', evidence: 'Evidence 1' },
                    { code: 'CODE-2', name: 'Item 2', evidence: 'Evidence 2' },
                ],
            },
            // Add more data objects as needed
        ];

        setStudentList(exampleData);
    };
    useEffect(() => {
        fetchStudentList();
    }, []);

    // State to manage open/closed state of each accordion row
    const [expandedRows, setExpandedRows] = useState({});
    // Function to toggle the accordion row's open/closed state
    const toggleAccordion = (index) => {
        setExpandedRows((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };
    // ========= End of Accordian Function ==========

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        setFormData((prevData) => ({
            ...prevData,
            [name]: newValue
        }));
    };


    const customSelectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? '#d0d0d0' : '#d0d0d0', // Border color changes on focus
            boxShadow: state.isFocused ? '0 0 5px #955cce' : 'none', // Box shadow changes on focus
            '&:hover': {
                borderColor: '#955cce', // Border color changes on hover
            },
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused ? '#c1acd9' : 'white', // Background color changes on option hover
            color: state.isFocused ? 'white' : 'black',
        }),
    };

    const [isChecked, setIsChecked] = useState(true);
    const [isPrivate, setIsPrivate] = useState(true)
    const [isPrivateMsg, setIsPrivateMsg] = useState('Class is Private')

    const handleCheckboxChange = () => {
        if (isChecked) {
            // setIsChecked((prevIsChecked) => !prevIsChecked);
            setIsChecked(false);
            setIsPrivate(false);
            setIsPrivateMsg('Class is Public')
        } else {
            setIsChecked(true);
            setIsPrivate(true);
            setIsPrivateMsg('Class is Private')
        }

    };

    // Style the QR Code
    const qrCode = new QRCodeStyling({
        width: 300,
        height: 300,
        image: QRLogo,
        dotsOptions: {
            color: "#393939",
            type: "classy"
        },
        imageOptions: {
            imageSize: 0.4,
            crossOrigin: "anonymous",
            margin: 5
        },
        backgroundOptions: {
            color: "transparent"
        },
        cornersSquareOptions: {
            type: "square",
            color: "#955CCE",
        }
    });

    return (

        <div>

            <div
                style={{
                    height: '100px',
                    width: '100%',
                }}
            ></div>
            {/* ======================================================This is where the number of arrowed tabs are set================================================================== */}

            <div className="container justify-content-left align-items-center">
                <div className="col-12">
                    <div className="tab" style={{ display: 'flex'}}>
                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 10 }}>
                            <div className={activeTab === 'Tab 1' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 1')}
                                // style={{zIndex: 94}}
                            >
                                Boxes
                            </div>

                        </div>

                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 9  }}>
                            <div className={activeTab === 'Tab 2' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 2')}
                                // style={{zIndex: 94}}
                            >
                                Dropdowns
                            </div>

                        </div>

                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 8 }}>
                            <div className={activeTab === 'Tab 3' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 3')}
                                // style={{zIndex: 94}}
                            >
                                Tables
                            </div>
                        </div>
                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 7  }}>
                            <div className={activeTab === 'Tab 4' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 4')}
                                // style={{zIndex: 94}}
                            >
                                Tab 4
                            </div>
                        </div>
                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 6 }}>
                            <div className={activeTab === 'Tab 5' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 5')}
                                // style={{zIndex: 94}}
                            >
                                Tab 5
                            </div>
                        </div>
                        <div className="arrow-tab2" style={{ display: 'flex', justifyContent: 'flex-start', zIndex: 5  }}>
                            <div className={activeTab === 'Tab 6' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 6')}
                                // style={{zIndex: 94}}
                            >
                                Tab 6
                            </div>
                        </div>
                        <div className="end-tab2" style={{ zIndex: 4 }}>
                            <div className={activeTab === 'Tab 7' ? 'arrow-tab active' : 'arrow-tab'}
                                 onClick={() => handleTabClick('Tab 7')}
                                // style={{zIndex: 94}}
                            >
                                Tab 7
                            </div>
                        </div>
                    </div>


                    {/* ===================================================================== TAB 1 =========================================================================*/}
                    {/* each arrowed Tab has its own master DIV with the id named there and refering to the tab setup above the TAB 1 marker.  All div rules are respected   */}

                    <div id="Tab 1" className="tabcontent">

                        <form onSubmit={handleSubmit}>

                            <Container style={{width:'100%'}}>

                                <Row style={{marginTop: 15, width:'100%' }}>  {/* This is the first row and start of the tab */}

                                    <Col xs={6} md={6} xl={6} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <div ref={QRCode} className={"qrCode"}>QR Code</div>
                                    </Col>

                                    <Col xs={6} md={6} xl={6} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                        <label htmlFor="textInput">This is a Text input Box:</label>
                                        <input
                                            id="textInput"                 //This is the id of the input box(normally make this something that refers to the variable above
                                            className="inputText"          //This is the CSS for this input box
                                            type="text"                    //This is the type of box that is created, this just says a general text input
                                            name="textInput"               //
                                            placeholder="This appears in background" //this is the text that will appear in the background of the input box
                                            value={formData.inputTextVal}  //for each variable this has to be declared here and up the top in the formData
                                            onChange={handleInputChange}   //triggers the variable to be updated in the database
                                            title="This comes up
                                                            with helpful
                                                            tool tips"             //this is where you put the tool tips and has Carridge returns where you put them.
                                            required                       //if you want someone to have to put data in here required makes sure they do
                                        />

                                        {/* =============================== Pastable Text input box ============================== */}

                                        {/*<Col xs={6} md={6} xl={6} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>*/}
                                        {/*    <label htmlFor="textInput">This is a Text input Box:</label>*/}
                                        {/*    <input*/}
                                        {/*        id="textInput"                 */}
                                        {/*        className="inputText"          */}
                                        {/*        type="text"                    */}
                                        {/*        name="textInput"               */}
                                        {/*        placeholder="This appears in background" */}
                                        {/*        value={formData.inputTextVal}  */}
                                        {/*        onChange={handleInputChange}   */}
                                        {/*        title="This comes up           */}
                                        {/*                        with helpful   */}
                                        {/*                        tool tips"     */}
                                        {/*        required                       */}
                                        {/*    />*/}

                                        <label htmlFor="comboBox">This is a Searchable Dropdown Combo Box:</label>
                                        <Select
                                            id="comboBox"                         //This is the id of the input box(normally make this something that refers to the variable above
                                            options={options}                     //This is the variable for the array of available options
                                            value={formData.dropdownVal}          //for each variable this has to be declared here and up the top in the formData
                                            styles={customSelectStyles}           //This is the CSS for this input box
                                            isClearable                           //Makes it searchable
                                            isSearchable                          //Makes it clearable
                                            placeholder="Select or type..."
                                            title="This is the physical street address where the training will
                                                        predominantly be undertaken and based from" //this is where you put the tool tips and has Carridge returns where you put them.
                                        />

                                        {/* ============================ Pastable Combo Box Example ================================ */}

                                        {/*<label htmlFor="comboBox">This is a Searchable Dropdown Combo Box:</label>*/}
                                        {/*<Select*/}
                                        {/*    id="comboBox"*/}
                                        {/*    options={options}*/}
                                        {/*    value={formData.dropdownVal}*/}
                                        {/*    styles={customSelectStyles}*/}
                                        {/*    isClearable*/}
                                        {/*    isSearchable*/}
                                        {/*    placeholder="Select or type..."*/}
                                        {/*    title="This is the physical street address where the training will*/}
                                        {/*                    predominantly be undertaken and based from"*/}
                                        {/*/>*/}

                                        <Row>
                                            <Col xs={6} md={6} xl={6} style={{marginTop: 15, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <label htmlFor="startDate">Date Input Box</label>
                                                <input
                                                    id="startDate"                 //This is the id of the input box(normally make this something that refers to the variable above
                                                    type="date"                    //This is the type of box that is created, this just says a general Date Field with Picker
                                                    name="startDate"               //
                                                    title="ToolTip"                //this is where you put the tool tips and has Carridge returns where you put them.
                                                    value={formData.startDateVal}  //for each variable this has to be declared here and up the top in the formData
                                                    onChange={handleInputChange}   //triggers the variable to be updated in the database
                                                />

                                            </Col>

                                            <Col xs={6} md={6} xl={6} style={{marginTop: 15, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>

                                                {/* ============================ Pastable Date Box Example ================================ */}

                                                <label htmlFor="finishDate">Date Input Box</label>
                                                <input
                                                    id="finishDate"
                                                    type="date"
                                                    name="finishDate"
                                                    value={formData.finishDateVal}
                                                    title="Tool Tip"
                                                    onChange={handleInputChange}
                                                />
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col xs={6} md={6} xl={6} style={{marginTop: 15, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <label htmlFor="startTime">Time Input Box</label>
                                                <input
                                                    id={"startTime"}                //This is the id of the input box(normally make this something that refers to the variable above
                                                    type="time"                     //This is the type of box that is created, this just says a Time formnatted field HH:MM
                                                    name="startTime"                //
                                                    value={formData.startTimeVal}   //for each variable this has to be declared here and up the top in the formData
                                                    title="Tool Tip"                //this is where you put the tool tips and has Carridge returns where you put them.
                                                    onChange={handleInputChange}    //triggers the variable to be updated in the database
                                                />

                                            </Col>

                                            <Col xs={6} md={6} xl={6} style={{marginTop: 15, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>

                                                {/* ============================ Pastable Time Box Example ================================ */}

                                                <label htmlFor="finishTime">Time Input Box</label>
                                                <input
                                                    id="finishTime"
                                                    type="time"
                                                    name="finishTime"
                                                    value={formData.finishTimeVal}
                                                    onChange={handleInputChange}
                                                    title="This is the Approximate finish time of the Class"
                                                />

                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col xs={6} md={6} xl={6} style={{marginTop: 15, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <label htmlFor="classCost">Currency Input Box</label>
                                                <CurrencyInput
                                                    className={"currencyInput"}     //CSS formatting for this element
                                                    id="courseCost"                 //This is the id of the input box(normally make this something that refers to the variable above
                                                    name="courseCost"               //
                                                    placeholder="$0.00"             //This is what appears in the Background of the box prior to being set
                                                    // defaultValue={0}                //This is the default
                                                    // value={formData.classCostVal}   //This is the Variable that is declared in the formValues above
                                                    decimalsLimit={2}               //The number of Decimal Places to go to
                                                    prefix={'$'}                    //The Currency Symbol to use
                                                    title="Tool Tip"                //this is where you put the tool tips and has Carridge returns where you put them.
                                                    onChange={handleInputChange}    //triggers the variable to be updated in the database
                                                />

                                                {/* ============================ Pastable Currency Box Example ================================ */}

                                                {/*<label htmlFor="classCost">Currency Input Box</label>*/}
                                                {/*<CurrencyInput*/}
                                                {/*    className={"currencyInput"}     //CSS formatting for this element*/}
                                                {/*    id="courseCost"                 //This is the id of the input box(normally make this something that refers to the variable above*/}
                                                {/*    name="courseCost"               //*/}
                                                {/*    placeholder="$0.00"             //This is what appears in the Background of the box prior to being set*/}
                                                {/*    // defaultValue={0}                //This is the default*/}
                                                {/*    // value={formData.classCostVal}   //This is the Variable that is declared in the formValues above*/}
                                                {/*    decimalsLimit={2}               //The number of Decimal Places to go to*/}
                                                {/*    prefix={'$'}                    //The Currency Symbol to use*/}
                                                {/*    title="Tool Tip"                //this is where you put the tool tips and has Carridge returns where you put them.*/}
                                                {/*    onChange={handleInputChange}    //triggers the variable to be updated in the database*/}
                                                {/*/>*/}

                                            </Col>

                                            <Col xs={6} md={6} xl={6} style={{marginTop: 15, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                                <label htmlFor="isPrivate" style={{marginRight: 15, marginBottom: 15}}>{isPrivateMsg}</label>
                                                <label className="slide-switch">         {/*//CSS formatting for this element*/}
                                                    <input                               // Don't Forget that there is other parts of this that are created in the Const Section
                                                        id="isPrivate"                   //This is the id of the input box(normally make this something that refers to the variable above
                                                        type="checkbox"                  //This is the type of box that is created, this just says a Time formnatted field HH:MM
                                                        checked={isChecked}              //This sets the default state of the button
                                                        title="Tool Tip"                 //this is where you put the tool tips and has Carridge returns where you put them.
                                                        onChange={handleCheckboxChange}  //triggers the variable to be updated in the database
                                                    />
                                                    <span className="slider"></span>
                                                </label>

                                                {/* ============================ Pastable Checkbox Example ================================ */}

                                                {/*<label htmlFor="isPrivate" style={{marginRight: 15, marginBottom: 15}}>{isPrivateMsg}</label>*/}
                                                {/*<label className="slide-switch">         */}
                                                {/*    <input                               */}
                                                {/*        id="isPrivate"                   */}
                                                {/*        type="checkbox"                  */}
                                                {/*        checked={isChecked}              */}
                                                {/*        title="Tool Tip"                 */}
                                                {/*        onChange={handleCheckboxChange}  */}
                                                {/*    />*/}
                                                {/*    <span className="slider"></span>*/}
                                                {/*</label>*/}

                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                            <Container>
                                <BorderedComponent name="This is just like a normal text enrty box but it pops off the page">
                                    <div style={{ width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                                        <input
                                            id="borderedComponent"                  //This is the id of the input box(normally make this something that refers to the variable above
                                            className="inputText"                   //This is the CSS for this input box
                                            type="text"                             //This is the type of box that is created, this just says a general text input
                                            name="txtCustom"                        //
                                            placeholder="This text appears in background"  //this is the text that will appear in the background of the input box
                                            value={formData.borderedComponentVal}   //This is the CSS for this input box
                                            onChange={handleInputChange}            //triggers the variable to be updated in the database
                                            title="Tool Tip"                        //this is where you put the tool tips and has Carridge returns where you put them.
                                        />
                                    </div>
                                </BorderedComponent>

{/* ============================ Pastable Bordered  Example ================================ */}

                                {/*<BorderedComponent name="This is just like a normal text enrty box but it pops off the page">*/}
                                {/*    <div style={{ width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}} >*/}
                                {/*        <input*/}
                                {/*            id="txtCustom"*/}
                                {/*            value={formData.borderedComponentVal}*/}
                                {/*            className="inputText"*/}
                                {/*            type="text"*/}
                                {/*            name="txtCustom"*/}
                                {/*            placeholder="The text you want prior to an entry"*/}
                                {/*            onChange={handleInputChange}*/}
                                {/*            title="Tool Tip"*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*</BorderedComponent>*/}




                            </Container>
                        </form>
                        <div style={{ display: 'flex', justifyContent: 'right', width: '100%', padding: 25}}>
                            <button type="submit"  className={"btn-primary"}>NEXT</button>

                        </div>
                    </div>



                    {/*========================================================= TAB 2 ======================================================================*/}

                    <div id="Tab 2" className="tabcontent">

                        <form onSubmit={handleSubmit}>

                            <div className={"row w-100"} style={{marginTop: 15}}>


                                <div className={"col-7"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', paddingLeft: 25}}>
                                    <label htmlFor="comboBox" style={{paddingTop: 25}}>Statement Type</label>
                                    <select
                                        name="statementType"
                                        value={formData.statementType}
                                        onChange={handleInputChange}
                                        title="This is the way the class has been delivered.
                                                Workplace is when delivered in conjunction with an employer
                                                Classroom Based is when they are only doing it in a classroom
                                                External is where they are doing it via correspondence"
                                    >
                                        <option value="SOA">Statement of Attainment Only (SOA is nationally Recognised Training)</option>
                                        <option value="SOC">Statement Of Completion Only (SOC is NOT nationally Recognised Training</option>
                                        <option value="PCOC">Partial Completion of Certificate (Used for when 1 Third of more of a Certificate is Planned</option>
                                        <option value="Cert">Certificate (Used when delivery of a full certificate is planned)</option>
                                    </select>

                                    <div className={"col-12"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center'}} >

                                        <label htmlFor="comboBox" style={{paddingTop: 25}}>Template:</label>
                                        <Select
                                            id="comboBox"
                                            options={options}
                                            value={formData.templateVal}
                                            styles={customSelectStyles}
                                            // styles={colourStyles}
                                            isClearable
                                            isSearchable
                                            placeholder="Select or type..."
                                            title="This is the physical street address where the training will
                                                predominantly be undertaken and based from"
                                        />
                                    </div>
                                    <div
                                        style={{
                                            height: '75px',
                                            width: '100%',
                                            paddingLeft: '25px',
                                            paddingRight: '25px',
                                            marginBottom: '25px',
                                            marginTop: 50
                                        }}
                                    >



                                    </div>
                                </div>
                                <div className={"row w-100"} style={{marginTop: 15}}>
                                    <div className={"col-12"} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center'}} >

                                        <h2>Modules</h2>
                                    </div>
                                </div>
                                <div className={"row w-100"} style={{marginTop: 15}}>
                                    <div className={"col-2"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center'}} >

                                        Code
                                    </div>
                                    <div className={"col-4"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center'}} >

                                        Name
                                    </div>
                                    <div className={"col-2"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center'}} >

                                        Flags
                                    </div>
                                </div>

                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: 25}}>

                                <button type="button" onClick={() => handleChangeTab('Tab 1')} className={"btn-primary"}>BACK</button>
                                <button type="submit"  className={"btn-primary"}>NEXT</button>

                            </div>


                        </form>
                    </div>




                    <div>



                    </div>


                    {/* =========================================================== TAB 3 ======================================================================*/}

                    <div id="Tab 3" className="tabcontent">
                        <form onSubmit={handleSubmit}>
                            <Row style={{paddingBottom: 50, paddingTop: 50, justifyContent: 'center'}}>
                                This is a module List Component
                                <ModuleTable />
                            </Row>
                            <Row style={{paddingBottom: 50, paddingTop: 50, justifyContent: 'center'}}>
                                This is an Accordion Table that expands when you hit an element
                                <table>
                                    <thead >
                                    <tr className={'tr2'} >
                                        <th>Student</th>
                                        <th>Mobile</th>
                                        <th>Billing</th>
                                        <th>USI</th>
                                        <th>Competent</th>
                                        <th>Actions</th>
                                        {/* Add more header columns as needed */}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {studentList.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <tr  onClick={() => toggleAccordion(index)} key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>
                                                <td>{row.heading}</td>
                                                <td>{row.mobile}</td>
                                                <td>{row.billing}</td>
                                                <td>{row.usi}</td>
                                                <td>{row.competent}</td>
                                                <td>{row.actions}</td>
                                                {/* Add more columns for extra headings as needed */}
                                            </tr>
                                            {expandedRows[index] && (
                                                <tr >
                                                    <td colSpan={12} style={{paddingTop: 0, padding: 0}} >
                                                        <table style={{paddingTop: 5, border: 0, width: '100%', paddingLeft: 0, paddingRight: 0}} key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>
                                                            <thead className={'tr2'}  >
                                                            <tr className={'tr2'}  >
                                                                <th style={{paddingLeft: 100, width: '20%'}} >Code</th>
                                                                <th style={{textAlign: 'left', paddingLeft: 50, width: '40%'}} >Name</th>
                                                                <th style={{textAlign: 'left', paddingLeft: 50, width: '40%'}} >Evidence</th>
                                                                {/* Add more header columns for extra line items as needed */}
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {row.extraLineItems.map((item, itemIndex) => (
                                                                <tr style={{paddingTop: 2, paddingBottom: 2}}>
                                                                    <td style={{paddingLeft: 100, width: '20%'}} >{item.code}</td>
                                                                    <td style={{textAlign: 'left', paddingLeft: 50, width: "40%"}} >{item.name}</td>
                                                                    <td style={{textAlign: 'left', paddingLeft: 50, width: '40%'}} >{item.evidence}</td>
                                                                    {/* Add more columns for extra line items as needed */}
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}
                                    </tbody>
                                </table>

                            </Row>

                        </form>

                        <Row>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: 25}}>
                                <button type="button" onClick={() => handleChangeTab('Tab 1')} className={"btn-primary"}>BACK</button>
                                <button type="submit"  className={"btn-primary"}>NEXT</button>
                            </div>
                        </Row>
                    </div>

                    {/* =========================================================== TAB 4 ======================================================================*/}

                    <div id="Tab 4" className="tabcontent">
                        <form onSubmit={handleSubmit}>
                            <div className={"row w-100"} style={{marginTop: 15}}>
                                <div className={"col-5"} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                    <h1 style={{height: '100%'}}>QRcode</h1>
                                </div>
                                <div className={"col-7"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                    <label htmlFor="comboBox">Training Venue -</label>
                                    <Select
                                        id="comboBox"
                                        value={formData.venueVal}
                                        options={options}
                                        styles={customSelectStyles}
                                        // onChange={handleCombo1Change}
                                        isClearable
                                        isSearchable
                                        placeholder="Venue Description"
                                        title="This is the name of the Physical Venue the training
                                            is being held at"
                                        required
                                    />
                                </div>
                                <div className={"col-7"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                    <label htmlFor="comboBox">Address</label>
                                    <input
                                        type="text"
                                        name="venueAddress1"
                                        value={formData.venueAddress1Val}
                                        placeholder="Venue Address"
                                        onChange={handleInputChange}
                                        title="This is the address of the venue"
                                    />
                                </div>
                                <div className={"col-7"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                    <input
                                        type="text"
                                        name="venueAddress2"
                                        value={formData.venueAddress2Val}
                                        placeholder="Venue Address"
                                        onChange={handleInputChange}
                                        title="This is line 2 of the address of the venue"
                                    />
                                </div><br/>
                                <div className={"col-4"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                    <input
                                        type="text"
                                        name="venueSuburb"
                                        value={formData.venueSuburbVal}
                                        placeholder="Suburb"
                                        onChange={handleInputChange}
                                        title="This is the Suburb of the venue"
                                    />
                                </div>
                                <div className={"col-1"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                    <input
                                        type="text"
                                        name="venueState"
                                        value={formData.venueStateVal}
                                        placeholder="State" onChange={handleInputChange}
                                        title="This is state of the venue"
                                    />
                                </div>
                                <div className={"col-2"} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'flex-center', alignItems: 'flex-center' }}>
                                    <input
                                        type="text"
                                        name="venuePostCode"
                                        value={formData.venuePostCodeVal}
                                        placeholder="PC"
                                        onChange={handleInputChange}
                                        title="This is the postcode of the venue"
                                    />
                                </div>
                                Phone
                                <input
                                    type="text"
                                    name="venuePhone"
                                    value={formData.venuePhoneVal}
                                    placeholder="Phone"
                                    onChange={handleInputChange}
                                    title="This is the Phone number for the venue"
                                />&nbsp;&nbsp;&nbsp;&nbsp;
                                Web
                                <input
                                    type="text"
                                    name="venueWeb"
                                    value={formData.venueWebVal}
                                    placeholder="Website URL"
                                    onChange={handleInputChange}
                                    title="This is the web site for the venue"
                                /><br/><br/>
                            </div>
                            <div className={"row w-100"} style={{marginTop: 15}}>
                                Venue Parking
                                <input
                                    type="text"
                                    name="venueParking"
                                    value={formData.venueParkingVal}
                                    placeholder="Parking"
                                    onChange={handleInputChange}
                                    title="This is special instructions for finding parking at the venue"
                                /> &nbsp;&nbsp;&nbsp;&nbsp;
                                Public Transport
                                <input
                                    type="text"
                                    name="venuePublicTransport"
                                    value={formData.venuePublicTransportVal}
                                    placeholder="Parking"
                                    onChange={handleInputChange}
                                    title="This is special instructions for finding parking at the venue"
                                /><br/><br/>

                                Disabled Access
                                <input
                                    type="text"
                                    name="venueDisable"
                                    value={formData.venueDisableVal}
                                    placeholder="Call ahead"
                                    onChange={handleInputChange}
                                    title="This is special instructions for Disabled Access at the venue"
                                /> &nbsp;&nbsp;&nbsp;&nbsp;
                                Lunch / Meals
                                <input
                                    type="text"
                                    name="venueMeals"
                                    value={formData.venueMealsVal}
                                    placeholder="Meal Accessibility"
                                    onChange={handleInputChange}
                                    title="This is to give people an idea of what food facilities will be
                                                available to them at the venue"
                                />
                                <br/><br/><br/><br/>
                                Venue WHS Audit
                                <br/><br/>
                                Auditor - FirstName Lastname &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                Phone - mobile# &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                Date -
                                <input
                                    type="date"
                                    name="auditDate"
                                    value={formData.venueAuditDateVal}
                                    title="This is the date that the Audit was done.  This will need
                                                to be re-updated at least quarterly"
                                    onChange={handleInputChange}
                                />&nbsp;<br/><br/>
                                HOUSEKEEPING (floors, Aisles, Work Areas) &nbsp;
                                <input
                                    type="checkbox"
                                    name="housekeepingSuitable"
                                    value={formData.venueHousekeepingVal}
                                    defaultChecked="unchecked"
                                    onChange={handleInputChange}
                                    title="When you are satisfied this is not a Danger to your students
                                                please tick this"
                                /> &nbsp;
                                <input
                                    type="text"
                                    name="housekeepingComments"
                                    value={formData.venueHousekeepingTxtVal}
                                    placeholder="What stood out or needs fixing?"
                                    onChange={handleInputChange}
                                    title="This is for commenting on the general state of the venues
                                                Housekeeping and listing anything that may need fixing"
                                /><br/><br/>
                                Lighting (Quality, Suitability) &nbsp;
                                <input
                                    type="checkbox"
                                    name="lightingSuitable"
                                    value={formData.venueLightingVal}
                                    defaultChecked="unchecked"
                                    onChange={handleInputChange}
                                    title="When you are satisfied the lighting is sufficient
                                                please tick this"
                                /> &nbsp;
                                <input
                                    type="text"
                                    name="lightingComments"
                                    value={formData.venueLightingTxtVal}
                                    placeholder="What stood out or needs fixing?"
                                    onChange={handleInputChange}
                                    title="This is to ensure that lighting is sufficient for people to
                                                undertake exams or learn at the venue"
                                /><br/><br/>
                                VENTILATION / AIR CONDITIONING (Source, Adequacy, Condition) &nbsp;
                                <input
                                    type="checkbox"
                                    name="ventilationSuitable"
                                    value={formData.venueVentilationVal}
                                    defaultChecked="unchecked"
                                    onChange={handleInputChange}
                                    title="When you are satisfied this supports a learning environment
                                                please tick this"
                                /> &nbsp;
                                <input
                                    type="text"
                                    name="ventialtionComments"
                                    value={formData.venueVentilationTxtVal}
                                    placeholder="What stood out or needs fixing?"
                                    onChange={handleInputChange}
                                    title="This is to ensure that Temperature Ranges etc are able to be
                                                kepy within ranges that support learning and limit distractions
                                                at the venue"
                                /><br/><br/>
                                NOISE (Source, Intensity) &nbsp;
                                <input
                                    type="checkbox"
                                    name="noiseSuitable"
                                    value={formData.venueNoiseVal}
                                    defaultChecked="unchecked"
                                    onChange={handleInputChange}
                                    title="When you are satisfied that there are no un-nessecary distractions
                                                please tick this"
                                /> &nbsp;
                                <input
                                    type="text"
                                    name="noiseComments"
                                    value={formData.venueNoiseTxtVal}
                                    placeholder="What stood out or needs fixing?"
                                    onChange={handleInputChange}
                                    title="This is to ensure that the level of noise at the venue will support
                                                the learning process and not allow or create too many distractions."
                                /><br/><br/>
                                HYGIENE / Sanitation (Facilities, Cleaning, Disposal) &nbsp;
                                <input
                                    type="checkbox"
                                    name="hygieneSuitable"
                                    value={formData.venueHygieneVal}
                                    defaultChecked="unchecked"
                                    onChange={handleInputChange}
                                    title="When you are satisfied that the general Hygiene and Sanitation are good
                                                please tick this"
                                /> &nbsp;
                                <input
                                    type="text"
                                    value={formData.venueHygieneTxtVal}
                                    name="hygieneComments"
                                    placeholder="What stood out or needs fixing?"
                                    onChange={handleInputChange}
                                    title="This is to ensure that the level of clenliness of the venue doesn't put
                                                people at risk."
                                /><br/><br/>
                                ELECTRICAL (Power Outlets, Cords, Connections, Switches) &nbsp;
                                <input
                                    type="checkbox"
                                    value={formData.venueElectricalVal}
                                    name="electricalSuitable"
                                    defaultChecked="unchecked"
                                    onChange={handleInputChange}
                                    title="When you are satisfied that there are no un-nessecary dangers due to
                                                electrical Dangers please tick this"
                                /> &nbsp;
                                <input
                                    type="text"
                                    value={formData.venueElectricalTxtVal}
                                    name="electricalComments"
                                    placeholder="What stood out or needs fixing?"
                                    onChange={handleInputChange}
                                    title="This is to ensure that the people are not put at unreasonable risk
                                                due to electrical installations."
                                /><br/><br/>
                                FIRST AID (Suitably Located, Stocked, Clean) &nbsp;
                                <input
                                    type="checkbox"
                                    name="firstaidSuitable"
                                    value={formData.venueFirstAidVal}
                                    defaultChecked="unchecked"
                                    onChange={handleInputChange}
                                    title="When you are satisfied that there are sufficient First Aid supplies
                                                available please tick this"
                                /> &nbsp;
                                <input
                                    type="text"
                                    name="firstaidComments"
                                    value={formData.venueFirstAidTxtVal}
                                    placeholder="What stood out or needs fixing?"
                                    onChange={handleInputChange}
                                    title="This is to ensure that the level of First Aid Facilities are able to
                                                handle the types of emergencies you may expect to encounter while
                                                conducting training."
                                /><br/><br/>
                                FIRE RISK (Extinguishers, Alarms, Flamable Materials) &nbsp;
                                <input
                                    type="checkbox"
                                    name="fireSuitable"
                                    value={formData.venueFireVal}
                                    defaultChecked="unchecked"
                                    onChange={handleInputChange}
                                    title="When you are satisfied that there are sufficient Fire Fighting Assets
                                                for the training you are conducting please tick this"
                                /> &nbsp;
                                <input
                                    type="text"
                                    name="fireComments"
                                    value={formData.venueFireTxtVal}
                                    placeholder="What stood out or needs fixing?"
                                    onChange={handleInputChange}
                                    title="This is to ensure that the fire fighting assets you would need to fight
                                                fires you would reasonably expect to result from training."
                                /><br/><br/>
                                SMOKING POLICY (Parties Advised, Enforced) &nbsp;
                                <input
                                    type="checkbox"
                                    name="smokingSuitable"
                                    value={formData.venueSmokingtVal}
                                    defaultChecked="unchecked"
                                    onChange={handleInputChange}
                                    title="When you are satisfied that the smoking policy has been identified
                                                communicated and implemented please tick this"
                                /> &nbsp;
                                <input
                                    type="text"
                                    name="smokingComments"
                                    value={formData.venueSmokingTxtVal}
                                    placeholder="What stood out or needs fixing?"
                                    onChange={handleInputChange}
                                    title="This is to ensure that the Smoking Policies for the Venue are Communicated
                                                and being adhered to within the Venue."
                                /><br/><br/><br/>
                                Special Notes / Follow Up:<br/>
                                <input
                                    type="text"
                                    name="specialComments"
                                    value={formData.venueSpecialTxtVal}
                                    placeholder="What needs to be followed up?"
                                    onChange={handleInputChange}
                                    title="This is to ensure that anything that needs to be followed up for
                                                rectification is being listed and passed on to someone that can
                                                action the change."
                                /><br/><br/><br/>


                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: 25}}>

                                <button type="button" onClick={() => handleChangeTab('Tab 2')} className={"btn-primary"}>BACK</button>
                                <button type="submit"className={"btn-primary"}>NEXT</button>

                            </div>
                        </form>
                    </div>
                    {/* =========================================================== TAB 5 ======================================================================*/}

                    <div id="Tab 5" className="tabcontent">
                        <h4>Tab 5</h4>
                        {/*<UploadDropzone />*/}
                        <Upload />
                    </div>

                    {/* =========================================================== TAB 6 ======================================================================*/}


                    {/*<div id="Tab 6" className="tabcontent">*/}
                    {/*    <h4>Tab 6</h4>*/}
                    {/*    <p>Stuff Here...</p>*/}
                    {/*</div>*/}

                    {/* =========================================================== TAB 7 ======================================================================*/}

                    <div id="Tab 7" className="tabcontent">
                        <h4>Tab 7</h4>
                        <p>Stuff Here...</p>
                    </div>
                </div>

            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    fontSize: '40px',
                    fontWeight: 'bold',
                }}
            >
                {pageName}


            </div>

        </div>
    );
}

// =============================== To Do =================================== //

// Rogue pixel hunt
// adjust for tablet view

// =============================== Bugs =================================== //

// Tabs need to have Z Value reset to incorporate Footer
// Not clickable under footer
