import { green, deepOrange, lightBlue } from '@mui/material/colors';

export const tasks = [
    {
        title: 'Recall Rebate Form',
        priorityId: 2,
        startDate: '2023-04-17T09:30',
        endDate: '2023-04-17T10:00',
    },
    {
        title: 'Create Report on Customer Feedback',
        priorityId: 2,
        startDate: '2023-04-17T10:30',
        endDate: '2023-04-17T12:00',
    },
    {
        title: 'Review Customer Feedback Report',
        priorityId: 2,
        startDate: '2023-04-17T12:15',
        endDate: '2023-04-17T13:30',
    },
    {
        title: 'Google AdWords Strategy',
        priorityId: 2,
        startDate: '2023-04-20T09:30',
        endDate: '2023-04-20T11:30',
    },
    {
        title: 'Rollout of New Website and Marketing Brochures',
        priorityId: 2,
        startDate: '2023-04-19T10:30',
        endDate: '2023-04-19T12:30',
    },
    {
        title: 'Create New Shipping Return Labels',
        priorityId: 3,
        startDate: '2023-04-19T13:00',
        endDate: '2023-04-19T14:30',
    },
    {
        title: 'Update Sales Strategy Documents',
        priorityId: 1,
        startDate: '2023-04-18T11:00',
        endDate: '2023-04-18T11:30',
    },
    {
        title: 'Website Re-Design Plan',
        priorityId: 2,
        startDate: '2023-04-27T09:30',
        endDate: '2023-04-27T11:30',
        allDay: true,
    },
    {
        title: 'Book Flights to San Fran for Sales Trip',
        priorityId: 2,
        startDate: '2023-04-27T12:00',
        endDate: '2023-04-27T13:00',
    },
    {
        title: 'Install New Router in Dev Room',
        priorityId: 2,
        startDate: '2023-04-27T14:30',
        endDate: '2023-04-27T15:30',
    },
    {
        title: 'Approve Personal Computer Upgrade Plan',
        priorityId: 2,
        startDate: '2023-04-23T10:00',
        endDate: '2023-04-23T11:00',
    },
    {
        title: 'Final Budget Review',
        priorityId: 2,
        startDate: '2023-04-23T12:00',
        endDate: '2023-04-23T13:35',
    },
    {
        title: 'New Brochures',
        priorityId: 3,
        startDate: '2023-04-23T14:30',
        endDate: '2023-04-23T15:45',
    },
    {
        title: 'Install New Database',
        priorityId: 3,
        startDate: '2023-04-24T09:45',
        endDate: '2023-04-24T11:15',
    },
    {
        title: 'Approve New Online Marketing Strategy',
        priorityId: 2,
        startDate: '2023-04-24T12:00',
        endDate: '2023-04-24T14:00',
    },
    {
        title: 'Upgrade Personal Computers',
        priorityId: 2,
        startDate: '2023-04-24T15:15',
        endDate: '2023-04-24T16:30',
    },
    {
        title: 'Customer Workshop',
        priorityId: 3,
        startDate: '2023-04-25T11:00',
        endDate: '2023-04-25T12:00',
        allDay: true,
    },
    {
        title: 'Prepare 2019 Marketing Plan',
        priorityId: 1,
        startDate: '2023-04-25T11:00',
        endDate: '2023-04-25T13:30',
    },
    {
        title: 'Brochure Design Review',
        priorityId: 1,
        startDate: '2023-04-25T14:00',
        endDate: '2023-04-25T15:30',
    },
    {
        title: 'Create Icons for Website',
        priorityId: 3,
        startDate: '2023-04-26T10:00',
        endDate: '2023-04-26T11:30',
    },
    {
        title: 'Upgrade Server Hardware',
        priorityId: 2,
        startDate: '2023-04-26T14:30',
        endDate: '2023-04-26T16:00',
    },
    {
        title: 'Submit New Website Design',
        priorityId: 1,
        startDate: '2023-04-26T16:30',
        endDate: '2023-04-26T18:00',
    },
    {
        title: 'Launch New Website',
        priorityId: 3,
        startDate: '2023-04-26T12:20',
        endDate: '2023-04-26T14:00',
    },
    {
        title: 'Non-Compete Agreements',
        priorityId: 1,
        startDate: '2023-04-30T12:20',
        endDate: '2023-04-30T13:00',
    },
    {
        title: 'Approve Hiring of John Jeffers',
        priorityId: 3,
        startDate: '2023-04-30T13:30',
        endDate: '2023-04-30T14:00',
    },
    {
        title: 'Review Training Course for any Ommissions',
        priorityId: 3,
        startDate: '2023-04-30T09:30',
        endDate: '2023-04-30T10:00',
    },
    {
        title: 'Update NDA Agreement',
        priorityId: 2,
        startDate: '2023-05-01T12:00',
        endDate: '2023-05-01T15:00',
    },
    {
        title: 'Update Employee Files with New NDA',
        priorityId: 2,
        startDate: '2023-05-02T09:00',
        endDate: '2023-05-02T10:00',
    },
    {
        title: 'Submit Questions Regarding New NDA',
        priorityId: 2,
        startDate: '2023-05-02T10:15',
        endDate: '2023-05-02T10:45',
    },
    {
        title: 'Submit Signed NDA',
        priorityId: 2,
        startDate: '2023-05-02T10:45',
        endDate: '2023-05-02T11:15',
    },
    {
        title: 'Review Revenue Projections',
        priorityId: 1,
        startDate: '2023-05-02T12:00',
        endDate: '2023-05-02T12:30',
    },
    {
        title: 'Comment on Revenue Projections',
        priorityId: 1,
        startDate: '2023-05-02T13:00',
        endDate: '2023-05-02T14:00',
    },
    {
        title: 'Provide New Health Insurance Docs',
        priorityId: 2,
        startDate: '2023-05-03T10:00',
        endDate: '2023-05-03T11:30',
    },
    {
        title: 'Review Changes to Health Insurance Coverage',
        priorityId: 2,
        startDate: '2023-05-03T11:45',
        endDate: '2023-05-03T13:30',
    },
];

export const priorities = [
    { id: 1, text: 'Low Priority', color: green },
    { id: 2, text: 'Medium Priority', color: lightBlue },
    { id: 3, text: 'High Priority', color: deepOrange },
];

export const owners = [
    {
        text: 'Craig',
        id: 1,
        color: '#324f0b',
    }, {
        text: 'Des',
        id: 2,
        color: '#FF7043',
    }, {
        text: 'Alex',
        id: 3,
        color: '#E91E63',
    }, {
        text: 'Matt',
        id: 4,
        color: '#406796',
    }, {
        text: 'Steve',
        id: 5,
        color: '#e34f0a',
    }, {
        text: 'Dave',
        id: 6,
        color: '#FFA726',
    },
];
