import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';

const LoginComponent = () => {

    const [inputs, setInputs] = useState({"longExpiry":false});
    const [msg, setMsg] = useState(window.sessionStorage.getItem('message'));
    const [msgCode, setMsgCode] = useState(0)
    // const feedBack = document.getElementById("userFeedback");

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        fetch('', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(inputs)
        })

            .then(response => response.json().then(data => {

                switch(response.status) {
                    case 200:
                        // console.log(window.sessionStorage.getItem('userLoggedIn'));
                        window.sessionStorage.setItem("bearerToken", data.bearerToken.token);
                        window.sessionStorage.setItem("bearerTokenExpiry", data.bearerToken.expires_in);
                        window.sessionStorage.setItem("bearerTokenType", data.bearerToken.token_type);
                        window.sessionStorage.setItem("refreshToken", data.refreshToken.token);
                        window.sessionStorage.setItem("refreshTokenExpiry", data.refreshToken.expires_in);
                        window.sessionStorage.setItem("timerSet", "no");
                        window.sessionStorage.setItem("userLoggedIn", "true");
                        window.sessionStorage.setItem("message", "You are signed in.");
                        window.sessionStorage.setItem("timerStartTime", "");
                        window.sessionStorage.setItem("searchMovie", "")
                        window.localStorage.setItem("timerStartTime", Date.now());
                        setMsgCode(4);
                        window.location.href = '/';
                        break;
                    case 400:
                        setMsg('Please enter a valid username and password.')
                        setMsgCode(1);
                        break;
                    case 401:
                        console.log('Incorrect email or password');
                        setMsg('Please enter a valid username and password.')
                        setMsgCode(1);
                        break;
                    case 429:
                        console.log('Too many requests');
                        alert('WOOOAH... Too many requests - Slow down Turbot!')
                        setMsg('A network error has occurred. Please try again later!')
                        setMsgCode(2);
                        break;

                    default:
                        console.log('O_o    Sumfins Fucky');
                }

            }))

            .catch(err => err);

    }

    function Tooltip({ text, children }) {
        return (
            <div className="tooltip">
                {children}
                <span className="tooltiptext">{text}</span>
            </div>
        );
    }


    return (

        <div>
            <div style={{
                backgroundImage: `url("")`,
                height: '100vh',
                width: '100%',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}>

                <form onSubmit={handleSubmit}>


                    <MDBContainer style={{backgroundColor: 'transparent'}}>

                        <div className="align-items-end justify-content-end bg-transparent mb-12" style={{ height: "1000px" }}>

                            <div className="bg-transparent mb-12" style={{ height: "30vh" }}/>

                            <h3>PLEASE ENTER YOU USERNAME AND PASSWORD</h3>

                            <label id="userFeedback" style={{color: '#8B0606'}}>{msg}</label>

                            <MDBRow>
                                <MDBCol end style={{ padding: "10px"}}>

                                        <input
                                        type="text"
                                        name="email"
                                        className="inputBox"
                                        placeholder={"USERNAME"}
                                        onChange={handleChange}
                                        title='Your UserName can be either your Mobile Phone Number or your email Address'
                                        required
                                    />


                                </MDBCol>
                            </MDBRow>

                            <MDBRow>
                                <MDBCol end style={{ padding: "10px"}}>

                                    <input
                                        type="password"
                                        name="password"
                                        className="inputBox"
                                        placeholder={"PASSWORD"}
                                        onChange={handleChange}
                                        title='Please ensure you have at least 8 characters with at least 1 Capital, 1 lower case, 1 number and 1 Special Character'
                                        required
                                    />
                                </MDBCol>
                            </MDBRow>

                            <input type="hidden" name="longExpiry" value="false"/>

                            <MDBRow>
                                <MDBCol center>
                                    <button style={{marginTop: 15}} className="btn-primary" type="submit">SIGN IN</button>
                                </MDBCol>
                            </MDBRow>


                        </div>
                    </MDBContainer>

                </form>
            </div>
        </div>
    );
};

export default LoginComponent

