import React, {useContext, useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import LoginComponent from '../Components/LoginComponent';
import backgroundImage from "../Images/TPLogoHighRes.png";
import Search from "../Components/Search"
import {ConstLink} from "../ConstLink";


export default function LoginPage() {

    const { isLoggedIn, setIsLoggedIn } = useContext(ConstLink);

    return (

        <div

            style={{
                height: '100vh',
                width: '100%',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
            }}
        >
            <div
                className="image-overlay"
                style={{
                    position: 'absolute',
                    top: 100,
                    right: 100,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${backgroundImage})`,
                    opacity: 0.3,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right center',
                    backgroundSize: 'auto 100%',
                }}
            ></div>
            <div
                style={{
                    position: 'relative',
                    height: '100vh',
                    width: '100%',

                }}
            >

                <div>

                    <LoginComponent />

                </div>
            </div>
        </div>
    )
}
