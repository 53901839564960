import React, { useEffect, useState } from 'react';
import CertsTable from "../../../Components/CertsTable";
import Bluey from "../../../Components/Bluey"

export default function Certificates() {
    const [pageName, setPageName] = useState('');

    useEffect(() => {
        const pathArray = window.location.pathname.split('/');
        const pageParam = pathArray[pathArray.length - 1];
        setPageName(pageParam || 'Default Page');
    }, []);

    return (

        <div>

            <Bluey />

            <div className={"page-common"} style={{ marginTop: 100, zIndex: 1 }}>

                <CertsTable />
s
            </div>

        </div>
    );
}

