import React, { useContext, useState, useRef, useEffect, Fragment } from 'react';
import { useNavigate, Link } from "react-router-dom";
import {ConstLink } from '../ConstLink';

import logo from '../Images/logo4.png';

const Header = () => {

    const navigate = useNavigate();
    const { closeHeaderMenu, toggleMenu, isActive, isLoggedIn } = useContext(ConstLink);

    const menuRef = useRef(null);
    // const [isActive, setIsActive] = useState(false);
    const [username, setUsername] = useState('GUEST');

    const [isMenuCollapsed, setIsMenuCollapsed] = useState(true);
    const [activeAccordion, setActiveAccordion] = useState(null);
    const [activeSubAccordion, setActiveSubAccordion] = useState(null);
    const activeElementRef = useRef(null);
    const reportsSubMenuRef = useRef(null);
    const setupSubMenuRef = useRef(null);
    const toolsSubMenuRef = useRef(null);
    const uploadsSubMenuRef = useRef(null);
    // const defaultColor = document.documentElement.style.getPropertyValue('--dropdownBackground');
    const [defaultColor, setDefaultColor] = useState('#595959');
    const [newColor, setNewColor] = useState('#595959');
    const [buttonColors, setButtonColors] = useState([defaultColor, defaultColor, defaultColor, defaultColor]);
    const defaultColors = [defaultColor, defaultColor, defaultColor, defaultColor];

    useEffect(() => {
        // console.log(defaultColor);
        setButtonColors([defaultColor, defaultColor, defaultColor, defaultColor])
    }, [defaultColor]);

    useEffect(() => {
        const colorCorrection = () => {
            if (window.innerWidth > 992) {
                setDefaultColor('#393939');
            }
        };

        // Run the colorCorrection function only when the viewport size is greater than 992 pixels
        if (window.innerWidth > 992) {
            colorCorrection();
        }

        // Add an event listener to handle changes in viewport size
        const handleResize = () => {
            if (window.innerWidth > 992) {
                colorCorrection();
            }
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const changeColor = (index) => {
        if (window.innerWidth > 992) {
            const updatedColors = [...buttonColors];
            updatedColors[index] = newColor;
            setButtonColors(updatedColors);
        }
    };

    const changeColorBack = (index) => {
        if (window.innerWidth > 992) {
            const updatedColors = [...buttonColors];
            updatedColors[index] = defaultColor;
            setButtonColors(updatedColors);
        }
    };

    const handleLogout = () => {
        // Handle logout logic here
    };

    const toggleHeaderMenu = () => {
        toggleMenu();
    };

    const navigateTo = (address) => {
        if (window.innerWidth < 900) {
            closeHeaderMenu(); // Close the navigation menu by calling the toggleMenu function
        }
        navigate(address);
        setActiveAccordion(null)
        setActiveSubAccordion(null)
    };

    const handleAccordionToggle = (index) => {

        if (window.innerWidth > 992) {
            setActiveAccordion((activeAccordion) => (activeAccordion === index ? null : index));
        } else {

            if (activeAccordion === index) {
                setActiveAccordion(null)
            } else {
                setActiveAccordion(index)
            }
        }
    };

    const handleAccordionOpen = (index) => {
        setActiveAccordion(index)
    };

    const handleAccordionClose = (index) => {
        setActiveAccordion(null)
    };

    const handleSubAccordionToggle = (index) => {
        if (window.innerWidth > 992) {
            setActiveSubAccordion((activeSubAccordion) => (activeSubAccordion === index ? null : index));
        } else {
            if (activeSubAccordion === null ) {
                setActiveSubAccordion(index)
            } else {
                setActiveSubAccordion(null)
                changeColorBack(index)
            }
        }
    };

    const handleSubAccordionOpen = (index) => {
        setActiveSubAccordion(index)
        // const delay = 500; // Delay in milliseconds
        // setTimeout(() => {
        //     setActiveSubAccordion(index);
        // }, delay);
    };

    const handleSubAccordionClose = (index) => {
        setActiveSubAccordion(null)
    };

    const handleMenuItemClick = () => {
        const checkbox1 = document.getElementById('checkbox1');
        const checkbox2 = document.getElementById('checkbox2');
        const checkbox3 = document.getElementById('checkbox3');

        if (checkbox1) {
            checkbox1.checked = false;
        }

        if (checkbox2) {
            checkbox2.checked = false;
        }

        if (checkbox3) {
            checkbox3.checked = false;
        }
    };

    // This tracks the pixel under the mouse and closes the sub menu
    const handleMouseMove = (event, elementRef) => {

        const { clientX } = event;
        const { clientY } = event;

        const activeElement = elementRef.current;
        const { left, right, top, bottom } = activeElement.getBoundingClientRect();

        // console.log('Top: ' + top);
        // console.log('Y:' + clientY);
        // console.log('Top: ' + top + ' ,Bottom: ' + bottom);
        // console.log('X:' + clientX + ' ,Y: ' + clientY);

        const offsetLeftPixel = left - 300;
        const offsetRightPixel = right - 300;
        const bottomPixel = top - 60

        if (clientX <= offsetLeftPixel || offsetRightPixel <= clientX) {
            // Trigger the onMouseLeave function
            handleSubAccordionClose(0);
        }

    };

    return (
        <div className="header-overflow">
            <nav>

                <div className="header-container">

                    <section className="menu menu--circle">
                        <input type="checkbox" id="menu__active"/>
                        <label htmlFor="menu__active" className="menu__active">
                            <div className="menu__toggle">
                                <div className="icon">
                                    <img src={logo} style={{width: 80, zIndex: 9999}} alt="roo"/>
                                </div>
                            </div>
                            <input id="checkbox1" type="radio" name="arrow--up" id="degree--up-0" hidden={true}/>
                            <input id="checkbox2" type="radio" name="arrow--up" id="degree--up-1" hidden={true}/>
                            <input id="checkbox3" type="radio" name="arrow--up" id="degree--up-2" hidden={true}/>
                            <div
                                className="menu__listings"
                                ref={menuRef}
                            >
                                <ul className="circle">
                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} onClick={() => navigateTo('/Portal/Administration/Setup')}className="button"><i className="fa fa-cog"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} onClick={() => {navigateTo('/'); handleMenuItemClick();}} className="button"><i
                                                    className="fa fa-home"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} className="button"><i className="fa fa-user"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} className="button"><i className="fa fa-commenting"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} className="button"><i
                                                    className="fa fa-folder-open"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} className="button"><i
                                                    className="fa fa-envelope"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} className="button"><i
                                                    className="fa fa-calendar"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} className="button"><i className="fa fa-cloud"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} className="button"><i
                                                    className="fa fa-id-card"></i></a>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="placeholder">
                                            <div className="upside">
                                                <a draggable={false} onClick={() => navigateTo('/Portal/History')} className="button"><i
                                                    className="fa fa-history"></i></a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                            <div className="menu__arrow menu__arrow--top">
                                <ul>
                                    <li>
                                        <label htmlFor="degree--up-0">
                                            <div className="arrow"></div>
                                        </label>
                                        <label htmlFor="degree--up-1">
                                            <div className="arrow"></div>
                                        </label>
                                        <label htmlFor="degree--up-2">
                                            <div className="arrow"></div>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </label>
                    </section>

                        <div className="navbar-container" >

                            {isLoggedIn && (

                                <nav className="navbar navbar-expand-lg">

                                    <button className="navbar-toggler" type="button" style={{outline: 'none', border: 'none'}}
                                            onClick={toggleHeaderMenu}
                                        >
                                        <span style={{color: 'white'}}>MENU</span>
                                    </button>

                                    <div className={`collapse navbar-collapse ${isActive ? 'show' : ''}`}>

                                        <ul className="navbar-nav mb-2 mb-lg-0" style={{}}>

                                            <li>
                                                <button className="customSpacer"></button>
                                            </li>

                                            <li>
                                                <button
                                                    className={`customToggle ${activeAccordion === 0 ? 'active' : ''}`}
                                                    onMouseOver={() => handleAccordionOpen(0)}
                                                    // onMouseLeave={() => handleAccordionClose(0)}
                                                    onClick={() => handleAccordionToggle(0)}
                                                >PEOPLE
                                                </button>

                                                <ul className={`dropdown-menu ${activeAccordion === 0 ? 'show' : ''}`}
                                                    onMouseLeave={() => handleAccordionClose(0)}>
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/people')}>CANDIDATES</button></li>
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/People')}>TRAINERS</button></li>
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/People')}>BDMS</button></li>
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/Register-Interest')}>REGISTER INTEREST</button></li>
                                                </ul>
                                            </li>

                                            <li>
                                                <button
                                                    className={`customToggle ${activeAccordion === 1 ? 'active' : ''}`}
                                                    onMouseOver={() => handleAccordionOpen(1)}
                                                    // onMouseLeave={() => handleAccordionClose(1)}
                                                    onClick={() => handleAccordionToggle(1)}
                                                >BUSINESSES
                                                </button>

                                                <ul className={`dropdown-menu ${activeAccordion === 1 ? 'show' : ''}`}
                                                    onMouseLeave={() => handleAccordionClose(0)}>
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/Businesses')}>BUSINESSES</button></li>
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/Businesses/Venues-View')}>VENUES</button></li>
                                                </ul>
                                            </li>

                                            <li>
                                                <button
                                                    className={`customToggle ${activeAccordion === 3 ? 'active' : ''}`}
                                                    onMouseOver={() => handleAccordionOpen(3)}
                                                    // onMouseLeave={() => handleAccordionClose(3)}
                                                    onClick={() => handleAccordionToggle(3)}
                                                >CALENDAR
                                                </button>

                                                <ul className={`dropdown-menu ${activeAccordion === 3 ? 'show' : ''}`}
                                                    onMouseLeave={() => handleAccordionClose(0)}>
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/Calendar')}>CALENDAR</button></li>
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/Calendar/My-Classes')}>MY CLASSES</button></li>
                                                </ul>
                                            </li>

                                            <li>
                                                <button
                                                    className={`customToggle ${activeAccordion === 2 ? 'active' : ''}`}
                                                    onMouseOver={() => handleAccordionOpen(2)}
                                                    // onMouseLeave={() => handleAccordionClose(2)}
                                                    onClick={() => handleAccordionToggle(2)}
                                                >CERTS & MODULES
                                                </button>

                                                <ul className={`dropdown-menu ${activeAccordion === 2 ? 'show' : ''}`}
                                                    onMouseLeave={() => handleAccordionClose(0)}>
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/CertificatesModules/Certificates')}>CERTIFICATES</button></li>
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/CertificatesModules/Modules')}>MODULES</button></li>
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/CertificatesModules/Certificate-Templates')}>CERTIFICATE TEMPLATES</button></li>
                                                    {/*<li><button className="customDropdown" onClick={() => navigateTo('/Portal/CertificatesModules/Certificate-Create')}>CERT C</button></li>*/}
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/CertificatesModules/Module-Visibility-Current')}>CURRENT MODULE VISIBILITY</button></li>
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/CertificatesModules/Module-Visibility-Old')}>OLD MODULE VISIBILITY</button></li>
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/CertificatesModules/Module-Billing')}>MODULE BILLING</button></li>
                                                </ul>
                                            </li>

                                            <li>
                                                <button
                                                    className={`customToggle ${activeAccordion === 4 ? 'active' : ''}`}
                                                    onMouseOver={() => handleAccordionOpen(4)}
                                                    // onMouseLeave={() => handleAccordionClose(4)}
                                                    onClick={() => handleAccordionToggle(4)}
                                                >DOCUMENTATION
                                                </button>

                                                <ul className={`dropdown-menu ${activeAccordion === 4 ? 'show' : ''}`}
                                                    onMouseLeave={() => handleAccordionClose(0)}>
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/Documentation/Document-Repository')}>REPOSITORY</button></li>
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/Documentation/Policies-Procedures')}>POLICIES & PROCEDURES</button></li>
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/Documentation/Marketing')}>MARKETING</button></li>
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/Documentation/Compliance-Auditing')}>COMPLIANCE & AUDITING</button></li>
                                                </ul>
                                            </li>

                                            <li>
                                                <button
                                                    className={`customToggle ${activeAccordion === 6 ? 'active' : ''}`}
                                                    onMouseOver={() => handleAccordionOpen(6)}
                                                    // onMouseLeave={() => handleAccordionClose(6)}
                                                    onClick={() => handleAccordionToggle(6)}
                                                >TASKS
                                                </button>

                                                <ul className={`dropdown-menu ${activeAccordion === 6 ? 'show' : ''}`}
                                                    onMouseLeave={() => handleAccordionClose(0)}>
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/Tasks/New-Task')}>NEW TASK</button></li>
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/Tasks/Unassigned-Tasks')}>UNASSIGNED TASKS</button></li>
                                                    <li><button className="customDropdown" onClick={() => navigateTo('/Portal/Tasks/Marking')}>MARKING</button></li>
                                                </ul>
                                            </li>

                                            <li>
                                                <button
                                                    className={`customToggle ${activeAccordion === 5 ? 'active' : ''}`}
                                                    onMouseOver={() => handleAccordionOpen(5)}
                                                    // onMouseLeave={() => handleAccordionClose(6)}
                                                    onClick={() => handleAccordionToggle(5)}
                                                    // style={{marginRight: '250px'}}
                                                >ADMIN
                                                </button>

                                                <ul className={`dropdown-menu ${activeAccordion === 5 ? 'show' : ''}`}

                                                    onMouseLeave={() => {handleAccordionClose(0); handleSubAccordionClose(0)}}>

                                                    <li>
                                                        <button
                                                            className={`customDropdown ${activeSubAccordion === 0 ? 'active' : ''}`}
                                                            onMouseOver={() => {handleSubAccordionOpen(0); setButtonColors(defaultColors)}}
                                                            onMouseLeave={() => changeColor(0)}
                                                            style={{ backgroundColor: buttonColors[0] }}
                                                            onClick={() => handleSubAccordionToggle(0)}
                                                        >REPORTS
                                                        </button>

                                                        <ul ref={reportsSubMenuRef} className={`dropdown-menu ${activeSubAccordion === 0 ? 'show' : ''}`}
                                                            onMouseMove={(event) => handleMouseMove(event, reportsSubMenuRef)}
                                                            onMouseLeave={() => changeColorBack(0)}>

                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/Avetmiss-Report')}>AVETMISS REPORT</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/Billing-Report')}>BILLING REPORT</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/Module-Reports')}>MODULE REPORT</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/White-Card-Report')}>WHITE CARD REPORT</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/Usi-Report')}>USI REPORT</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/View-Funding-Prices')}>VIEW FUNDING PRICES</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/Active-Certificate-Report')}>ACTIVE CERTIFICATE REPORT</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/Certificates-Unassigned-Report')}>CERTIFICATES UNASSIGNED REPORT</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/Backup-Report')}>BACKUP REPORT</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/Email-Report')}>EMAIL REPORT</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/Event-Reports')}>EVENT REPORT</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/Invalid-Usi-Report')}>INVALID USI REPORT</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/Invoice-Reminders')}>INVOICE REMINDERS</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/Lui-Report')}>LUI REPORT</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/Module-Kpis')}>MODULE KPIs</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/Outstanding-Invoices-Report')}>OUTSTANDING INVOICES REPORT</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/Qi-Ccos')}>QI CCOS REPORT</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/Registered-Interest-Report')}>REGISTERED INTEREST REPORT</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/Report-Settings')}>REPORT SETTINGS</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/Superseded-Module-Report')}>SUPERSEDED MODULE REPORT</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Reports/View-Payment-Data')}>VIEW PAYMENT DATA</button></li>
                                                        </ul>
                                                    </li>

                                                    <li>
                                                        <button
                                                            className={`customDropdown ${activeSubAccordion === 1 ? 'active' : ''}`}
                                                            onMouseOver={() => {handleSubAccordionOpen(1); setButtonColors(defaultColors)}}
                                                            onMouseLeave={() => changeColor(1)}
                                                            style={{ backgroundColor: buttonColors[1] }}
                                                            onClick={() => handleSubAccordionToggle(1)}
                                                        >SETUP
                                                        </button>

                                                        <ul ref={setupSubMenuRef} className={`dropdown-menu ${activeSubAccordion === 1 ? 'show' : ''}`}
                                                            onMouseMove={(event) => handleMouseMove(event, setupSubMenuRef)}
                                                            onMouseLeave={() => changeColorBack(1)}>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Setup/Branding')}>BRANDING</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Setup/Create-Funding-Prices')}>CREATE FUNDING PRICES</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Setup/Permissions')}>ROLE PERMISSIONS</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Scraper')}>CURRENT SCRAPER</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Setup/Integration-Settings')}>INTEGRATION</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Setup/Search-Event-Actions')}>SHOW EVENT ACTIONS</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Setup/Event-Action-Editor')}>CREATE EVENT ACTIONS</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Setup/Templates')}>MESSAGE TEMPLATES</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Setup/Task-Group-Edit')}>EVENT TRIGGERS</button></li>
                                                        </ul>
                                                    </li>

                                                    <li>
                                                        <button
                                                            className={`customDropdown ${activeSubAccordion === 2 ? 'active' : ''}`}
                                                            onMouseOver={() => {handleSubAccordionOpen(2); setButtonColors(defaultColors)}}
                                                            onMouseLeave={() => changeColor(2)}
                                                            style={{ backgroundColor: buttonColors[2] }}
                                                            onClick={() => handleSubAccordionToggle(2)}
                                                        >TOOLS
                                                        </button>

                                                        <ul ref={toolsSubMenuRef} className={`dropdown-menu ${activeSubAccordion === 2 ? 'show' : ''}`}
                                                            onMouseMove={(event) => handleMouseMove(event, toolsSubMenuRef)}
                                                            onMouseLeave={() => changeColorBack(2)}>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Tools/Statement-Fix-Name')}>FIX STATEMENT NAME</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Tools/Billing-Fix-Reports-Tool')}>FIX BILLING REPORTS</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Tools/Dev-Tools')}>DEV TOOLS</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Tools/Bulk-Verify-Usis')}>BULK VERRIFY USIs</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Tools/Credit-Transfer-Tool')}>CREDIT TRANSFERS</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Tools/Usi-Generator')}>USI GENERATOR</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Tools/Certificate-Transition-Tool')}>CERTIFICATE TRANSFER TOOL</button></li>
                                                        </ul>
                                                    </li>

                                                    <li>
                                                        <button
                                                            className={`customDropdown ${activeSubAccordion === 3 ? 'active' : ''}`}
                                                            onMouseOver={() => handleSubAccordionOpen(3)}
                                                            onMouseLeave={() => changeColor(3)}
                                                            style={{ backgroundColor: buttonColors[3] }}
                                                            onClick={() => handleSubAccordionToggle(3)}
                                                        >UPLOADS
                                                        </button>

                                                        <ul ref={uploadsSubMenuRef} className={`dropdown-menu ${activeSubAccordion === 3 ? 'show' : ''}`}
                                                            onMouseMove={(event) => handleMouseMove(event, uploadsSubMenuRef)}
                                                            onMouseLeave={() => changeColorBack(3)}>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Uploads/Upload-Assessment')}>ASSESSMENT</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Uploads/Upload-Det-Payment')}>QGOV FUNDING PAYMENT</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Uploads/Upload-Det-Data-Error-Validation')}>QGOV ERROR REPORT</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Uploads/Upload-Det-Modules-Snapshot')}>QGOV FUNDED SCOPE(Sch A)</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Uploads/Upload-Approved-Training-Snapshot')}>QGOV APPROVED APPRENTICESHIPS</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Uploads/Upload-User-Choice-Pricelist')}>QGOV UC PRICE LIST</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Uploads/Upload-Nominal-Hours')}>NATIONAL NOMINAL HOURS</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Uploads/Upload-Legacy-Assessment')}>LEGACY ASSESSMENT</button></li>
                                                            <li><button className="customSubDropdown" onClick={() => navigateTo('/Portal/Administration/Uploads/Upload-Det-Payment')}>AVETMISS FROM OTHER SOFTWARE</button></li>
                                                        </ul>
                                                    </li>
                                                </ul>

                                            </li>

                                            <li>
                                                <button className="customSpacer"></button>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            )}


                            <div className="profile-container">

                                <a className="username">
                                    {username}
                                </a>

                                <a className="header-button">
                                    <i onClick={() => navigateTo('/Portal/Personal-Profile')}
                                       className="fa fa-user"></i>
                                </a>
                                <a className="header-button">
                                    {isLoggedIn ? (
                                        <i onClick={() => navigateTo('/Login')}
                                           className="fa fa-lock-open"></i>
                                    ) : (
                                        <i onClick={() => navigateTo('/Login')}
                                           className="fa fa-lock" ></i>
                                    )}
                                </a>
                                <a className="header-button">
                                        <i onClick={() => navigateTo('')}
                                           className="fa fa-search"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        );
    };


export default Header;
