import logo from './logo.svg';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './Styling/App.css';
import './Styling/Header.css';
import './Styling/Footer.css';
import { BrowserRouter as Router, Switch, Routes ,Route, Link } from "react-router-dom";
import { ConstProvider } from './ConstLink';

// Pages
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Home from "./Pages/Home";
import CoursesTraining from "./Pages/CoursesTraining";
import SiteManagementSystem from "./Pages/SiteManagementSystem";
import Contact from "./Pages/Contact";
import Employment from "./Pages/Employment";
import Competencies from "./Pages/Competencies";
import AssessmentMarking from "./Pages/AssessmentMarking";
import RegisterInterest from "./Pages/Portal/RegisterInterest";
import History from "./Pages/Portal/History";
import PersonalDetails from "./Pages/UserPortal/PersonalDetails";
import Certificate from "./Pages/UserPortal/Study/Certificate";
import Study from "./Pages/UserPortal/Study";
import Diary from "./Pages/UserPortal/Diary";
import Administration from "./Pages/Portal/Administration";
import AssessmentReview from "./Pages/Portal/AssessmentReview";
import C3gFeedback from "./Pages/Portal/C3gFeedback";
import Calendar from "./Pages/Portal/Calendar";
import Classroom from "./Pages/Portal/Classroom";
import ClassEdit from "./Pages/Portal/Calender/ClassEdit";
import MyClasses from "./Pages/Portal/Calender/MyClasses";
import CertificatesModules from "./Pages/Portal/CertificatesModules";
import Changelog from "./Pages/Portal/Changelog";
import Businesses from "./Pages/Portal/Businesses";
import VenuesView from "./Pages/Portal/Businesses/VenuesView";
import BusinessProfile from "./Pages/Portal/Businesses/BusinessProfile";
import DocumentCentre from "./Pages/Portal/DocumentCentre";
import DocumentCreator from "./Pages/Portal/DocumentCreator";
import Documentation from "./Pages/Portal/Documentation";
import Drive from "./Pages/Portal/Drive";
import NewTask from "./Pages/Portal/Tasks/NewTask";
import UnassignedTasks from "./Pages/Portal/Tasks/UnassignedTasks";
import Marking from "./Pages/Portal/Tasks/Marking";
import People from "./Pages/Portal/People";
import ReviewCentre from "./Pages/Portal/ReviewCentre";
import Scraper from "./Pages/Portal/Scraper";
import SetupAccount from "./Pages/Portal/SetupAccount";
import Tasks from "./Pages/Portal/Tasks";
import Verify from "./Pages/Portal/Verify";
import Metrics from "./Pages/Portal/Administration/Metrics";
import Reports from "./Pages/Portal/Administration/Reports";
import Setup from "./Pages/Portal/Administration/Setup";
import Tools from "./Pages/Portal/Administration/Tools";
import Uploads from "./Pages/Portal/Administration/Uploads";
import LuiReport from "./Pages/Portal/Administration/Reports/LuiReport";
import SupersededModuleReport from "./Pages/Portal/Administration/Reports/SupersededModuleReport";
import ViewPaymentData from "./Pages/Portal/Administration/Reports/ViewPaymentData";
import InvalidUsiReport from "./Pages/Portal/Administration/Reports/InvalidUsiReport";
import OutstandingInvoicesReport from "./Pages/Portal/Administration/Reports/OutstandingInvoicesReport";
import ModuleKpis from "./Pages/Portal/Administration/Reports/ModuleKpis";
import RegisteredInterestReport from "./Pages/Portal/Administration/Reports/RegisteredInterestReport";
import ActiveCertificateReport from "./Pages/Portal/Administration/Reports/ActiveCertificateReport";
import ReportSettings from "./Pages/Portal/Administration/Reports/ReportSettings";
import ModuleReports from "./Pages/Portal/Administration/Reports/ModuleReports";
import WhiteCardReport from "./Pages/Portal/Administration/Reports/WhiteCardReport";
import BackupReport from "./Pages/Portal/Administration/Reports/BackupReport";
import CertificatesUnassignedReport from "./Pages/Portal/Administration/Reports/CertificatesUnassignedReport";
import QiCcos from "./Pages/Portal/Administration/Reports/QiCcos";
import BillingReport from "./Pages/Portal/Administration/Reports/BillingReport";
import AvetmissReport from "./Pages/Portal/Administration/Reports/AvetmissReport";
import EmailReport from "./Pages/Portal/Administration/Reports/EmailReport";
import EventReports from "./Pages/Portal/Administration/Reports/EventReports";
import InvoiceReminders from "./Pages/Portal/Administration/Reports/InvoiceReminders";
import ViewFundingPrices from "./Pages/Portal/Administration/Reports/ViewFundingPrices";
import UsiReport from "./Pages/Portal/Administration/Reports/UsiReport";
import TaskGroupEdit from "./Pages/Portal/Administration/Setup/TaskGroupEdit";
import Permissions from "./Pages/Portal/Administration/Setup/Permissions";
import IntegrationSettings from "./Pages/Portal/Administration/Setup/IntegrationSettings";
import Branding from "./Pages/Portal/Administration/Setup/Branding";
import SearchEventActions from "./Pages/Portal/Administration/Setup/SearchEventActions";
import EventActionEditor from "./Pages/Portal/Administration/Setup/EventActionEditor";
import Accounting from "./Pages/Portal/Administration/Setup/Accounting";
import CreateFundingPrices from "./Pages/Portal/Administration/Setup/CreateFundingPrices";
import Templates from "./Pages/Portal/Administration/Setup/Templates";
import UsiGenerator from "./Pages/Portal/Administration/Tools/UsiGenerator";
import StatementFixName from "./Pages/Portal/Administration/Tools/StatementFixName";
import BillingFixReportsTool from "./Pages/Portal/Administration/Tools/BillingFixReportsTool";
import CreditTransferTool from "./Pages/Portal/Administration/Tools/CreditTransferTool";
import BulkVerifyUsis from "./Pages/Portal/Administration/Tools/BulkVerifyUsis";
import BulkUploadTas from "./Pages/Portal/Administration/Tools/BulkUploadTas";
import CertificateTransitionTool from "./Pages/Portal/Administration/Tools/CertificateTransitionTool";
import DevTools from "./Pages/Portal/Administration/Tools/DevTools";
import UploadApprovedTrainingSnapshot from "./Pages/Portal/Administration/Uploads/UploadApprovedTrainingSnapshot";
import UploadDetPayment from "./Pages/Portal/Administration/Uploads/UploadDetPayment";
import UploadDetModulesSnapshot from "./Pages/Portal/Administration/Uploads/UploadDetModulesSnapshot";
import ScheduleAUploader from "./Pages/Portal/Administration/Uploads/ScheduleAUploader";
import UploadLegacyAssessment from "./Pages/Portal/Administration/Uploads/UploadLegacyAssessment";
import UploadDetDataErrorValidation from "./Pages/Portal/Administration/Uploads/UploadDetDataErrorValidation";
import UploadNominalHours from "./Pages/Portal/Administration/Uploads/UploadNominalHours";
import UploadUserChoicePriceList from "./Pages/Portal/Administration/Uploads/UploadUserChoicePriceList";
import UploadAvetmissReport from "./Pages/Portal/Administration/Uploads/UploadAvetmissReport";
import UploadAssessment from "./Pages/Portal/Administration/Uploads/UploadAssessment";
import ClassAttendance from "./Pages/Portal/CertificatesModules/ClassAttendance";
import Certificates from "./Pages/Portal/CertificatesModules/Certificates";
import Modules from "./Pages/Portal/CertificatesModules/Modules";
import CertificateTemplate from "./Pages/Portal/CertificatesModules/CertificateTemplate";
import CertificateTemplates from "./Pages/Portal/CertificatesModules/CertificateTemplates";
import CertificateCreation from "./Pages/Portal/CertificatesModules/CertificateCreation";
import ModuleVisibilityCurrent from "./Pages/Portal/CertificatesModules/ModuleVisibilityCurrent";
import ModuleVisibilityOld from "./Pages/Portal/CertificatesModules/ModuleVisibilityOld";
import ModuleBilling from "./Pages/Portal/CertificatesModules/ModuleBilling";
import DocumentRepository from "./Pages/Portal/Documentation/DocumentRepository";
import PoliciesProcedures from "./Pages/Portal/Documentation/PoliciesProcedures";
import ComplianceAuditing from "./Pages/Portal/Documentation/ComplianceAuditing";
import Marketing from "./Pages/Portal/Documentation/Marketing";
import SQEMP from "./Pages/Portal/Documentation/SQEMP";
import QaModuleReview from "./Pages/Portal/ReviewCentre/QaModuleReview";
import QaReviewList from "./Pages/Portal/ReviewCentre/QaReviewList";
import PendingEventActionSubmissions from "./Pages/Portal/ReviewCentre/PendingEventActionSubmissions";
import LearnerResourceReview from "./Pages/Portal/ReviewCentre/LearnerResourceReview";
import PendingTemplateSubmissions from "./Pages/Portal/ReviewCentre/PendingTemplateSubmissions";
import Template from "./Pages/Template";
import TemplateSteps from "./Pages/TemplateSteps";
import TemplateBoxes from "./Pages/TemplateBoxes";
import PersonalProfile from "./Pages/Portal/PersonalProfile";
import Test from "./Pages/Test";



// const isLoggedIn = false; // Replace with authentication logic

// Components
import Header from "./Components/Header";
import Footer from "./Components/Footer";



import React, { useState } from "react";
function App() {
  return (

    <Router>
      <div className="App">

        {/*<head>*/}

        {/*  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"></link>*/}
        {/*  <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>*/}
        {/*  <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.5.4/dist/umd/popper.min.js"></script>*/}
        {/*  <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.min.js"></script>*/}

        {/*</head>*/}

        <ConstProvider>
          <header className="App-header">
            <div className="header-overflow"><Header /></div>
          </header>

          {/*<body>*/}

            <Routes>

              <Route path="/" element={<Home/>} />
              <Route path="/Login" element={<Login/>} />
              <Route path="/Register" element={<Register/>} />
              <Route path="/Template" element={<Template/>} />
              <Route path="/Classroom" element={<Classroom/>} />
              <Route path="/Site-Management-System" element={<SiteManagementSystem/>} />
              <Route path="/Contact" element={<Contact/>} />
              <Route path="/Employment" element={<Employment/>} />
              <Route path="/Competencies" element={<Competencies/>} />
              <Route path="/Assessment-Marking" element={<AssessmentMarking/>} />
              <Route path="/UserPortal/Personal-Details" element={<PersonalDetails/>} />
              <Route path="/UserPortal/Study" element={<Study/>} />
              <Route path="/UserPortal/Diary" element={<Diary/>} />
              <Route path="/UserPortal/Study/Certificate" element={<Certificate/>} />
              <Route path="/Courses-Training" element={<CoursesTraining/>} />
              <Route path="/Portal/Tasks/New-Task" element={<NewTask/>} />
              <Route path="/Portal/Tasks/Marking" element={<Marking/>} />
              <Route path="/Portal/Tasks/Unassigned-Tasks" element={<UnassignedTasks/>} />
              <Route path="/Portal/Administration" element={<Administration/>} />
              <Route path="/Portal/Register-Interest" element={<RegisterInterest/>} />
              <Route path="/Portal/Assessment-Review" element={<AssessmentReview/>} />
              <Route path="/Portal/C3g-Feedback" element={<C3gFeedback/>} />
              <Route path="/Portal/History" element={<History/>} />
              <Route path="/Portal/Certificates-Modules" element={<CertificatesModules/>} />
              <Route path="/Portal/Changelog" element={<Changelog/>} />
              <Route path="/Portal/Businesses" element={<Businesses/>} />
              <Route path="/Portal/Businesses/Venues-View" element={<VenuesView/>} />
              <Route path="/Portal/Businesses/Business-Profile" element={<BusinessProfile/>} />
              <Route path="/Portal/Document-Centre" element={<DocumentCentre/>} />
              <Route path="/Portal/Document-Creator" element={<DocumentCreator/>} />
              <Route path="/Portal/Documentation" element={<Documentation/>} />
              <Route path="/Portal/Drive" element={<Drive/>} />
              <Route path="/Portal/People" element={<People/>} />
              <Route path="/Portal/Review-Centre" element={<ReviewCentre/>} />
              <Route path="/Portal/Scraper" element={<Scraper/>} />
              <Route path="/Portal/Setup-Account" element={<SetupAccount/>} />
              <Route path="/Portal/Tasks" element={<Tasks/>} />
              <Route path="/Portal/Verify" element={<Verify/>} />
              <Route path="/Portal/Administration/Metrics" element={<Metrics/>} />
              <Route path="/Portal/Administration/Reports" element={<Reports/>} />
              <Route path="/Portal/Administration/Setup" element={<Setup/>} />
              <Route path="/Portal/Administration/Tools" element={<Tools/>} />
              <Route path="/Portal/Administration/Uploads" element={<Uploads/>} />
              <Route path="/Portal/Administration/Reports/Lui-Report" element={<LuiReport/>} />
              <Route path="/Portal/Administration/Reports/View-Funding-Prices" element={<ViewFundingPrices/>} />
              <Route path="/Portal/Administration/Reports/Superseded-Module-Report" element={<SupersededModuleReport/>} />
              <Route path="/Portal/Administration/Reports/View-Payment-Data" element={<ViewPaymentData/>} />
              <Route path="/Portal/Administration/Reports/Invalid-Usi-Report" element={<InvalidUsiReport/>} />
              <Route path="/Portal/Administration/Reports/Outstanding-Invoices-Report" element={<OutstandingInvoicesReport/>} />
              <Route path="/Portal/Administration/Reports/Module-Kpis" element={<ModuleKpis/>} />
              <Route path="/Portal/Administration/Reports/Registered-Interest-Report" element={<RegisteredInterestReport/>} />
              <Route path="/Portal/Administration/Reports/Active-Certificate-Report" element={<ActiveCertificateReport/>} />
              <Route path="/Portal/Administration/Reports/Report-Settings" element={<ReportSettings/>} />
              <Route path="/Portal/Administration/Reports/Module-Reports" element={<ModuleReports/>} />
              <Route path="/Portal/Administration/Reports/White-Card-Report" element={<WhiteCardReport/>} />
              <Route path="/Portal/Administration/Reports/Backup-Report" element={<BackupReport/>} />
              <Route path="/Portal/Administration/Reports/Certificates-Unassigned-Report" element={<CertificatesUnassignedReport/>} />
              <Route path="/Portal/Administration/Reports/Qi-Ccos" element={<QiCcos/>} />
              <Route path="/Portal/Administration/Reports/Billing-Report" element={<BillingReport/>} />
              <Route path="/Portal/Administration/Reports/Avetmiss-Report" element={<AvetmissReport/>} />
              <Route path="/Portal/Administration/Reports/Email-Report" element={<EmailReport/>} />
              <Route path="/Portal/Administration/Reports/Event-Reports" element={<EventReports/>} />
              <Route path="/Portal/Administration/Reports/Usi-Report" element={<UsiReport/>} />
              <Route path="/Portal/Administration/Reports/Invoice-Reminders" element={<InvoiceReminders/>} />
              <Route path="/Portal/Administration/Setup/Task-Group-Edit" element={<TaskGroupEdit/>} />
              <Route path="/Portal/Administration/Setup/Permissions" element={<Permissions/>} />
              <Route path="/Portal/Administration/Setup/Integration-Settings" element={<IntegrationSettings/>} />
              <Route path="/Portal/Administration/Setup/Branding" element={<Branding/>} />
              <Route path="/Portal/Administration/Setup/Search-Event-Actions" element={<SearchEventActions/>} />
              <Route path="/Portal/Administration/Setup/Event-Action-Editor" element={<EventActionEditor/>} />
              <Route path="/Portal/Administration/Setup/Accounting" element={<Accounting/>} />
              <Route path="/Portal/Administration/Setup/Create-Funding-Prices" element={<CreateFundingPrices/>} />
              <Route path="/Portal/Administration/Setup/Templates" element={<Templates/>} />
              <Route path="/Portal/Administration/Tools/Usi-Generator" element={<UsiGenerator/>} />
              <Route path="/Portal/Administration/Tools/Statement-Fix-Name" element={<StatementFixName/>} />
              <Route path="/Portal/Administration/Tools/Billing-Fix-Reports-Tool" element={<BillingFixReportsTool/>} />
              <Route path="/Portal/Administration/Tools/Credit-Transfer-Tool" element={<CreditTransferTool/>} />
              <Route path="/Portal/Administration/Tools/Bulk-Verify-Usis" element={<BulkVerifyUsis/>} />
              <Route path="/Portal/Administration/Tools/Bulk-Upload-Tas" element={<BulkUploadTas/>} />
              <Route path="/Portal/Administration/Tools/Certificate-Transition-Tool" element={<CertificateTransitionTool/>} />
              <Route path="/Portal/Administration/Tools/Dev-Tools" element={<DevTools/>} />
              <Route path="/Portal/Administration/Uploads/Upload-Approved-Training-Snapshot" element={<UploadApprovedTrainingSnapshot/>} />
              <Route path="/Portal/Administration/Uploads/Upload-Det-Payment" element={<UploadDetPayment/>} />
              <Route path="/Portal/Administration/Uploads/Upload-Det-Modules-Snapshot" element={<UploadDetModulesSnapshot/>} />
              <Route path="/Portal/Administration/Uploads/Schedule-A-UploadDropzone" element={<ScheduleAUploader/>} />
              <Route path="/Portal/Administration/Uploads/Upload-Legacy-Assessment" element={<UploadLegacyAssessment/>} />
              <Route path="/Portal/Administration/Uploads/Upload-Det-Data-Error-Validation" element={<UploadDetDataErrorValidation/>} />
              <Route path="/Portal/Administration/Uploads/Upload-Nominal-Hours" element={<UploadNominalHours/>} />
              <Route path="/Portal/Administration/Uploads/Upload-User-Choice-Price-List" element={<UploadUserChoicePriceList/>} />
              <Route path="/Portal/Administration/Uploads/Upload-Avetmiss-Report" element={<UploadAvetmissReport/>} />
              <Route path="/Portal/Administration/Uploads/Upload-Assessment" element={<UploadAssessment/>} />
              <Route path="/Portal/CertificatesModules/Class-Attendance" element={<ClassAttendance/>} />
              <Route path="/Portal/CertificatesModules/Certificates" element={<Certificates/>} />
              <Route path="/Portal/CertificatesModules/Modules" element={<Modules/>} />
              <Route path="/Portal/CertificatesModules/Module-Visibility-Current" element={<ModuleVisibilityCurrent/>} />
              <Route path="/Portal/CertificatesModules/Module-Visibility-Old" element={<ModuleVisibilityOld/>} />
              <Route path="/Portal/CertificatesModules/Module-Billing" element={<ModuleBilling/>} />
              <Route path="/Portal/CertificatesModules/Certificate-Template" element={<CertificateTemplate/>} />
              <Route path="/Portal/CertificatesModules/Certificate-Templates" element={<CertificateTemplates/>} />
              <Route path="/Portal/CertificatesModules/Certificate-Create" element={<CertificateCreation/>} />
              <Route path="/Portal/Documentation/Document-Repository" element={<DocumentRepository/>} />
              <Route path="/Portal/Documentation/Policies-Procedures" element={<PoliciesProcedures/>} />
              <Route path="/Portal/Documentation/Compliance-Auditing" element={<ComplianceAuditing/>} />
              <Route path="/Portal/Documentation/Marketing" element={<Marketing/>} />
              <Route path="/Portal/Documentation/S-Q-E-M-P" element={<SQEMP/>} />
              <Route path="/Portal/Review-Centre/Qa-Module-Review" element={<QaModuleReview/>} />
              <Route path="/Portal/Review-Centre/Qa-Review-List" element={<QaReviewList/>} />
              <Route path="/Portal/Review-Centre/Pending-Event-Action-Submissions" element={<PendingEventActionSubmissions/>} />
              <Route path="/Portal/Review-Centre/Learner-Resource-Review" element={<LearnerResourceReview/>} />
              <Route path="/Portal/Review-Centre/Pending-Template-Submissions" element={<PendingTemplateSubmissions/>} />
              <Route path="/Portal/Calendar" element={<Calendar/>} />
              <Route path="/Portal/Calendar/Class-Edit" element={<ClassEdit/>} />
              <Route path="/Portal/Calendar/My-Classes" element={<MyClasses/>} />
              <Route path="/Portal/Personal-Profile" element={<PersonalProfile/>} />
              <Route path="/TemplateSteps" element={<TemplateSteps/>} />
              <Route path="/TemplateBoxes" element={<TemplateBoxes/>} />
              <Route path="/Test" element={<Test/>} />

            </Routes>

            {/*</body>*/}
          <footer>
            <Footer />
          </footer>
        </ConstProvider>
      </div>
    </Router>
  );
}

export default App;
