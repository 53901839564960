import { useState, useEffect } from 'react';
import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { Alert, AlertTitle } from '@mui/material'


export default function Register() {

    const [inputs, setInputs] = useState({});
    const responseData = document.getElementById("respData");
    const [msg, setMsg] = useState(window.sessionStorage.getItem('message'))
    const [msgCode, setMsgCode] = useState(0)

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        if ( inputs.password === inputs.password2 ) {

            // console.log('Passwords match');

            fetch('', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(inputs)
            })
                .then(response => {

                    console.log(response.status);

                    if ( response.status === 201 ) {
                        console.log('User created');
                        window.location.href = '/login';
                        setMsg('User created');
                        setMsgCode(4);
                    } else if ( response.status === 400 ) {
                        console.log('Request body incomplete, both email and password are required');
                        setMsg('Please enter a valid username and password.');
                        setMsgCode(1);
                    } else if ( response.status === 409 ) {
                        console.log('User already exists');
                        setMsg('User already exists');
                        setMsgCode(1);
                    } else if ( response.status === 429 ) {
                        console.log('Too many requests');
                        setMsg('Too many requests');
                        alert('WOOOAH... Too many requests - Slow down Turbot!')
                        setMsgCode(2)
                    } else {
                        console.log( 'O_o    Sumfins Fucky');
                        setMsg('A network error has occurred. Please try again later!');
                        setMsgCode(3)
                    }

                }).catch(err => err);

        } else {

            console.log('Passwords do not match');
            setMsg('The passwords you entered do not match');
            setMsgCode(3);

        }

    }

    return (

        <div style={{
            // backgroundImage: `url("")`,
            height: '100vh',
            width: '100%',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        }}>

            <header style={{ color: "#988558", backgroundColor: "charcoal" }} className="App-header">

                <form onSubmit={handleSubmit}>

                    <MDBContainer>

                        <div className="align-items-end justify-content-end bg-transparent mb-12" style={{ height: "1000px" }}>

                            <div className="bg-transparent mb-12" style={{ height: "250px" }}/>

                            <h3>REGISTER FOR A SHINY NEW ACCOUNT</h3>

                            {/*<label id="userFeedback" style={{color: '#8B0606'}}>{msg}</label>*/}

                            <div>
                                {msgCode === 1 && (
                                    <Alert
                                        severity="error"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            textAlign: "center",
                                        }}
                                    ><strong style={{ fontSize: "18px" }}>{msg}</strong>
                                    </Alert>
                                )}
                                {msgCode === 2 && (
                                    <Alert severity="warning"
                                           style={{
                                               display: "flex",
                                               alignItems: "center",
                                               justifyContent: "center",
                                               flexDirection: "column",
                                               textAlign: "center",
                                           }}
                                    ><strong style={{ fontSize: "18px" }}>{msg}</strong>
                                    </Alert>
                                )}
                                {msgCode === 3 && (
                                    <Alert severity="info"
                                           style={{
                                               display: "flex",
                                               alignItems: "center",
                                               justifyContent: "center",
                                               flexDirection: "column",
                                               textAlign: "center",
                                           }}
                                    ><strong style={{ fontSize: "18px" }}>{msg}</strong>
                                    </Alert>
                                )}
                                {msgCode === 4 && (
                                    <Alert severity="success"
                                           style={{
                                               display: "flex",
                                               alignItems: "center",
                                               justifyContent: "center",
                                               flexDirection: "column",
                                               textAlign: "center",
                                           }}
                                    ><strong style={{ fontSize: "18px" }}>{msg}</strong>
                                    </Alert>
                                )}
                            </div>

                            <MDBRow>
                                <MDBCol end style={{ padding: "10px"}}>
                                    {/*<label visible={false} style={{ padding: "10px" }}>Email: </label>*/}
                                    <input
                                        type="text"
                                        name="email"
                                        className="inputBox"
                                        style={{width: 500}}
                                        placeholder={"PLEASE ENTER YOUR EMAIL ADDRESS"}
                                        onChange={handleChange}
                                        required
                                    />
                                </MDBCol>
                            </MDBRow>

                            <MDBRow>
                                <MDBCol end style={{ padding: "10px", width: "100%"}}>
                                    {/*<label style={{ padding: "10px" }}>Password: </label>*/}
                                    <input
                                        type="password"
                                        name="password"
                                        className="inputBox"
                                        style={{width: 300}}
                                        placeholder={"PLEASE ENTER A PASSWORD"}
                                        required
                                        onChange={handleChange}

                                    />
                                </MDBCol>
                            </MDBRow>

                            <MDBRow>
                                <MDBCol end style={{ padding: "10px", width: "100%"}}>
                                    {/*<label style={{ padding: "10px" }}>Confirm Password: </label>*/}
                                    <input
                                        type="password"
                                        name="password2"
                                        className="inputBox"
                                        style={{width: 300}}
                                        placeholder={"PLEASE CONFIRM YOUR PASSWORD"}
                                        required
                                        onChange={handleChange}

                                    />
                                </MDBCol>
                            </MDBRow>

                            <MDBRow>
                                <MDBCol center>
                                    <button type="submit" className="button">REGISTER</button>
                                </MDBCol>
                            </MDBRow>

                        </div>
                    </MDBContainer>



                </form>

            </header>

        </div>
    );

}