import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from './Routes';


const Search = () => {

    const [searchValue, setSearchValue] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();

        // Filter to check if the search content is a number
        const numberFilter = /^\d+$/;
        const moduleFilter = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]+$/;
        const nameFilter = /^[A-Za-z]+\s[A-Za-z]+$/;

        switch (true) {
            case numberFilter.test(searchValue):
                console.log('Search value is a number:', searchValue);
                navigate(`/search?number=${searchValue}`);
                break;

            case moduleFilter.test(searchValue):
                console.log('Search value is a module:', searchValue);
                navigate(`portal/certificates-modules/modules/module-edit/?id=${searchValue}`);
                break;

            case nameFilter.test(searchValue):
                console.log('Search value is a person:', searchValue);
                navigate(`portal/people/candidate-edit/?id=${searchValue}`);
                break;

            default:
                console.log('Search value is a route:', searchValue);
                // Check if the searchValue is a route element
                const foundRoute = routes.find(
                    (route) => route.element.toLowerCase() === searchValue.toLowerCase()
                );
                if (foundRoute) {
                    console.log('Search value:', searchValue);
                    navigate(foundRoute.path);
                } else {
                    // Handle the case when no matching route is found
                    console.log('Search value:', searchValue);
                    console.log('No matching route found.');
                }
                break;
        }

        console.log('Search value:', searchValue);

    };

    const handleInputChange = (event) => {

        setSearchValue(event.target.value);
    };

    return (

        <div>
            <div className="search-container" >
                <form onSubmit={handleSubmit}>
                    {/*<div className="container" style={{ width: '100%', margin: '0'}}></div>*/}
                    <div className="search-bar">
                        <input
                            id="searchForDis"
                            type="text"
                            className="search-input"
                            value={searchValue} // Bind the input value to the state variable
                            onChange={handleInputChange} // Handle input change to update the state
                            placeholder="Search..."
                        />
                        <button type="submit" className="search-button">
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );

};

export default Search;




