import React, { useRef, useState, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import dayjs from 'dayjs';

const SignaturePanel = () => {
    const sigCanvas = useRef({});
    const [signature, setSignature] = useState(null);
    const [timestamp, setTimestamp] = useState(null);
    const [studentData, setStudentData] = useState({ name: '', id: '' });

    useEffect(() => {
        const name = sessionStorage.getItem('user_name');
        const id = sessionStorage.getItem('studentId');
        setStudentData({ name, id });
    }, []);

    const clearSignature = () => {
        sigCanvas.current.clear();
        setSignature(null);
        setTimestamp(null);
    };

    const saveSignature = () => {
        setSignature(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
        setTimestamp(dayjs().format('YYYY-MM-DD HH:mm:ss'));
    };

    const signContainerStyle = {
        padding: '25px',
        margin: '25px',
        border: '1px solid black',
        borderRadius: '15px',
        // backgroundColor: 'lightGrey',
    };

    const signCanvasStyle = {
        width: '100%',
        height: '500px',
        border: '1px solid black',
        borderRadius: '15px',
        display: 'block',
    };

    const signButtonContainerStyle = {
        marginTop: '15px',
    };

    return (
        <div style={signContainerStyle}>
            <h2>Signature Panel</h2>
            <p>Student Name: {studentData.name}</p>
            <p>Student ID: {studentData.id}</p>
            <SignatureCanvas
                ref={sigCanvas}
                canvasProps={{ style: signCanvasStyle }}
            />
            <div style={signButtonContainerStyle}>
                <button className={'btn-primary'} onClick={clearSignature}>Clear</button>
                <button className={'btn-primary'} onClick={saveSignature}>Save</button>
            </div>
            {signature && (
                <div>
                    <h3>Saved Signature:</h3>
                    <img src={signature} alt="signature" />
                    <p>Timestamp: {timestamp}</p>
                </div>
            )}
        </div>
    );
};


export default SignaturePanel;
