import React, { useEffect, useState } from 'react';

export default function UploadDetModulesSnapshot() {
    const [pageName, setPageName] = useState('');

    useEffect(() => {
        const pathArray = window.location.pathname.split('/');
        const pageParam = pathArray[pathArray.length - 1];
        setPageName(pageParam || 'Default Page');
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                fontSize: '40px',
                fontWeight: 'bold',
            }}
        >
            {pageName}
        </div>
    );
}

