import React from 'react';
import '../Styling/App.css'; // Import the CSS file for styling

const BorderedComponent = ({ name, children }) => {
    return (
        <div className="bordered-component">
            <div className="bordered-name">{name}</div>
            <div className="bordered-content">{children}</div>
        </div>
    );
};

export default BorderedComponent;