import React, {useContext, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {ConstLink} from "../ConstLink";


export default function Contacts() {

    const navigate = useNavigate();
    const { setQuickMenu, isLoggedIn } = useContext(ConstLink);

    if ( !isLoggedIn ) {
        navigate('/login')
    }
    useEffect(() => {
        // Run the updateQuickMenu1 function on page load
        updateQuickMenu();
    }, []);

    const updateQuickMenu = () => {
        setQuickMenu(
            "Item 1",
            "Item 2",
            "Item 3",
            "Item 4",
            "Item 5",
            "Item 6" );
    };

    const [pageName, setPageName] = useState('');

    window.localStorage.setItem("quickMenu1", "");
    window.localStorage.setItem("quickMenu2", "");
    window.localStorage.setItem("quickMenu3", "");
    window.localStorage.setItem("quickMenu4", "");
    window.localStorage.setItem("quickMenu5", "");
    window.localStorage.setItem("quickMenu6", "");

    useEffect(() => {
        const pathArray = window.location.pathname.split('/');
        const pageParam = pathArray[pathArray.length - 1];
        setPageName(pageParam || 'Default Page');
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                fontSize: '40px',
                fontWeight: 'bold',
            }}
        >
            {pageName}
        </div>
    );
}

// =============================== To Do =================================== //



// =============================== Bugs =================================== //