import React, { useState, useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import Upload from "./Dropzone/upload/Upload";
import {useNavigate} from "react-router-dom";

const ClassroomTab = () => {
    const [classId, setClassId] = useState('');
    const [studentList, setStudentList] = useState([]);
    const [error, setError] = useState('');
    const [file, setFile] = useState(null);
    const navigate = useNavigate();

    // Placeholder for handling marking when clicking on a student's name
    const handleMarking = (studentId) => {

        // Test module is 4757
        const modId = 4757; // Assuming a static modId for now

        const jsonObject = { modId: modId, studentId: studentId };
        window.sessionStorage.setItem("currentClassroom", modId);
        window.sessionStorage.setItem("currentStudent", studentId);
        // Navigate with State method
        navigate('/Portal/Tasks/Marking', { state: { jsonObject } });
        // navigate('/Portal/Tasks/Marking', { state: { jsonObject }, replace: true });

        console.log("Marking for student number:", studentId);
        // Placeholder logic
    };

    const fetchClassStatus = async () => {
        const studentIds = studentList.map(student => student.studentNumber);
        const modId = 3730; // Assuming a static modId for now

        try {
            const response = await fetch('https://api.trainingprofessionals.com.au/student/classStatus', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ studentIds, modId })
            });

            if (!response.ok) throw new Error('Failed to fetch class status');

            const statuses = await response.json();
            // console.log('Fetched statuses:', statuses); // Log the fetched statuses

            // Map through statuses and update studentList
            const updatedStudentList = studentList.map(student => {
                const status = statuses.find(s => s.studentId === student.studentNumber);
                // console.log(`Updating student ${student.studentNumber} with status:`, status); // Log each student update
                return {
                    ...student,
                    completedQuestions: status ? status.completedQuestions : 0,
                    totalQuestions: status ? status.totalQuestions : student.modules.length // Assuming student.modules.length is the total
                };
            });

            // console.log('Updated student list:', updatedStudentList); // Log the updated student list
            setStudentList(updatedStudentList);
        } catch (error) {
            console.error('Failed to fetch class status:', error);
        }
    };

    const fetchClassDetails = async () => {
        try {
            const response = await fetch('https://api.trainingprofessionals.com.au/calendar/class', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ classID: classId })
            });

            if (!response.ok) throw new Error('Failed to fetch data');

            const data = await response.json();
            sessionStorage.setItem('classDetails', JSON.stringify(data));
            setStudentList(data.students);
        } catch (error) {
            console.error('Failed to fetch class details:', error);
            setError('Failed to fetch class details');
        }
    };

    useEffect(() => {
        if (classId) {
            const interval = setInterval(() => {
                fetchClassStatus();
                console.log('Fetching student status...');
            }, 5000); // Fetch every 5 seconds

            return () => clearInterval(interval);
        }
    }, [classId, studentList]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            width: '100%',
            maxWidth: '960px',
            margin: '0 auto',
            background: '#fff',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
        }}>
            <form onSubmit={(e) => {
                e.preventDefault();
                fetchClassDetails();
            }} style={{ width: '100%' }}>
                <Row>
                    <Col xs={12} md={6} style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '20px',
                    }}>
                        <input
                            type="text"
                            name="Class details"
                            value={classId}
                            placeholder="Enter the class ID here"
                            onChange={(e) => setClassId(e.target.value)}
                            title="This is to ensure that only the students and modules for the class are loaded"
                            style={{ width: '100%', marginBottom: '20px' }}
                        />
                        <button type="submit" style={{ width: '200px', marginTop: '10px' }}>Fetch Class Details</button>
                    </Col>
                </Row>
            </form>

            {error && <p style={{ color: 'red', fontSize: '16px' }}>{error}</p>}

            <table style={{ width: '100%', marginTop: '20px' }}>
                <thead>
                <tr>
                    <th>Student</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {studentList.map((student, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>
                        <td onClick={() => handleMarking(student.studentNumber)} style={{ cursor: 'pointer' }}>{student.name}</td>
                        <td>{`${student.completedQuestions || 0} of ${student.totalQuestions || 0} questions answered`}</td>
                    </tr>
                ))}
                </tbody>
            </table>

            <button onClick={() => handleMarking(20646)} style={{ width: '200px', marginTop: '10px' }}>Test Marking</button>

        </div>
    );
};

export default ClassroomTab;
