import React, {useContext, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Search from '../Components/Search';
import backgroundImage from '../Images/TPLogoHighRes.png';
import {ConstLink} from "../ConstLink";
import routes from '../Components/Routes'; // Update the import path accordingly
import Bluey from "../Components/Bluey"

export default function Home() {

    const navigate = useNavigate();
    const { setQuickMenu, isLoggedIn } = useContext(ConstLink);

    // if ( !isLoggedIn ) {
    //     navigate('/login')
    // }

    useEffect(() => {
        // Run the updateQuickMenu1 function on page load
        updateQuickMenu();
    }, []);

    const updateQuickMenu = () => {
        setQuickMenu([
            ["My Students", "/Students"],
            ["Upcoming Classes", "/Classes"],
            ["Student Certificates", "/Certificates"],
            ["Businesses I Work With", "/Businesses"],
            ["Modules I can train", "/Modules"],
            ["Quality Control", "/QualityControl"]
        ]); // Update the value of quickMenu
    };

    const handleButtonClick = () => {
        navigate('portal/calendar/class-edit');
    };

    const handleNavigate = (module) => {

        const jsonObject = { modId: module };
        window.sessionStorage.setItem("currentClassroom", module);
        // Navigate with State method
        // navigate('/study/classroom/assessment', { state: { jsonObject } });
        navigate('/Classroom');
        // Navigate with JSON object
        // const serializedData = encodeURIComponent(JSON.stringify(yourJsonObject));
        // navigate(`/study/assessment?data=${serializedData}`);

        // Navigate with string
        // const message = "Hello, V!";
        // navigate(`/study/assessment?message=${encodeURIComponent(message)}`);

    };

    return (

        <div

            style={{
                height: '100vh',
                width: '100%',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
            }}
        >
            <Bluey />

            <div
                style={{
                    position: 'relative',
                    height: '100vh',
                    width: '100%',
                    paddingTop: '35vh',
                }}
            >

                <div className="container" style={{width: '50%'}}>

                    {/*<Search />*/}
                    <button className={'btn-primary'} onClick={handleButtonClick}>Go to Upload</button>
                    <button onClick={() => handleNavigate(3730)} className={'btn-primary'} style={{ marginLeft: 50}}>Test Marking</button>

                </div>
            </div>
        </div>
    );
}




