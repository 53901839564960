import React, {useContext, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import {ConstLink} from "../../ConstLink";
import Select from 'react-select';
import BorderedComponent from '../../Components/BorderedComponent';
// import Search from '../Components/Search';
// import backgroundImage from '../Images/TPLogoHighRes.png';
// import {ConstLink} from "../ConstLink";
import routes from '../../Components/Routes'; // Update the import path accordingly
// import Bluey from "../Components/Bluey"
import ClassroomTab from "../../Components/ClassroomTab";
// import TabFive from "../../Components/TabFive";

export default function Classroom() {

    const navigate = useNavigate();
    // const { setQuickMenu, isLoggedIn } = useContext(ConstLink);

    // if ( !isLoggedIn ) {
    //     navigate('/login')
    // }

    // useEffect(() => {
    //     // Run the updateQuickMenu1 function on page load
    //     // updateQuickMenu();
    // }, []);

    // const updateQuickMenu = () => {
        // setQuickMenu([
        //     ["My Students", "/Students"],
        //     ["Upcoming Classes", "/Classes"],
        //     ["Student Certificates", "/Certificates"],
        //     ["Businesses I Work With", "/Businesses"],
        //     ["Modules I can train", "/Modules"],
        //     ["Quality Control", "/QualityControl"]
        // ]); // Update the value of quickMenu
    // };

    const handleButtonClick = () => {
        navigate('portal/calendar/class-edit');
    };

    const handleNavigate = (module) => {

        const jsonObject = { modId: module };
        window.sessionStorage.setItem("currentClassroom", module);
        // Navigate with State method
        // navigate('/study/classroom/assessment', { state: { jsonObject } });
        navigate('/Portal/Tasks/Marking', { state: { jsonObject }, replace: true });
        // Navigate with JSON object
        // const serializedData = encodeURIComponent(JSON.stringify(yourJsonObject));
        // navigate(`/study/assessment?data=${serializedData}`);

        // Navigate with string
        // const message = "Hello, V!";
        // navigate(`/study/assessment?message=${encodeURIComponent(message)}`);

    };

    return (

        <div

            style={{

            }}
        >

            {/*<Bluey />*/}

            <div
                style={{

                }}
            >

                <div className="container" style={{width: '50%'}}>

                    {/*<Search />*/}
                    {/*<button className={'btn-primary'} onClick={handleButtonClick}>Go to Upload</button>*/}
                    {/*<button onClick={() => handleNavigate(3730)} className={'btn-primary'} style={{ marginBottom: 200}}>Test Marking</button>*/}


                </div>

                <ClassroomTab/>

                {/*<TabFive/>*/}

            </div>
        </div>
    );
}




