const routes=[

    { "element": "Home", "path": "/" },
    { "element": "Login", "path": "/Login" },
    { "element": "Register", "path": "/Register" },
    { "element": "Template", "path": "/Template" },
    { "element": "SiteManagementSystem", "path": "/Site-Management-System" },
    { "element": "Contact", "path": "/Contact" },
    { "element": "Employment", "path": "/Employment" },
    { "element": "Competencies", "path": "/Competencies" },
    { "element": "PersonalDetails", "path": "/UserPortal/Personal-Details" },
    { "element": "Study", "path": "/UserPortal/Study" },
    { "element": "Diary", "path": "/UserPortal/Diary" },
    { "element": "Certificate", "path": "/UserPortal/Study/Certificate" },
    { "element": "CoursesTraining", "path": "/Courses-Training" },
    { "element": "NewTask", "path": "/Portal/Tasks/New-Task" },
    { "element": "UnassignedTasks", "path": "/Portal/Tasks/Unassigned-Tasks" },
    { "element": "Administration", "path": "/Portal/Administration" },
    { "element": "RegisterInterest", "path": "/Portal/Register-Interest" },
    { "element": "AssessmentReview", "path": "/Portal/Assessment-Review" },
    { "element": "C3gFeedback", "path": "/Portal/C3g-Feedback" },
    { "element": "CertificatesModules", "path": "/Portal/Certificates-Modules" },
    { "element": "Changelog", "path": "/Portal/Changelog" },
    { "element": "Businesses", "path": "/Portal/Businesses" },
    { "element": "VenuesView", "path": "/Portal/Businesses/Venues-View" },
    { "element": "DocumentCentre", "path": "/Portal/Document-Centre" },
    { "element": "DocumentCreator", "path": "/Portal/Document-Creator" },
    { "element": "Documentation", "path": "/Portal/Documentation" },
    { "element": "Drive", "path": "/Portal/Drive" },
    { "element": "People", "path": "/Portal/People" },
    { "element": "ReviewCentre", "path": "/Portal/Review-Centre" },
    { "element": "Scraper", "path": "/Portal/Scraper" },
    { "element": "SetupAccount", "path": "/Portal/Setup-Account" },
    { "element": "Tasks", "path": "/Portal/Tasks" },
    { "element": "Verify", "path": "/Portal/Verify" },
    { "element": "Metrics", "path": "/Portal/Administration/Metrics" },
    { "element": "Reports", "path": "/Portal/Administration/Reports" },
    { "element": "Setup", "path": "/Portal/Administration/Setup" },
    { "element": "Tools", "path": "/Portal/Administration/Tools" },
    { "element": "Uploads", "path": "/Portal/Administration/Uploads" },
    { "element": "LuiReport", "path": "/Portal/Administration/Reports/Lui-Report" },
    { "element": "ViewFundingPrices", "path": "/Portal/Administration/Reports/View-Funding-Prices" },
    { "element": "SupersededModuleReport", "path": "/Portal/Administration/Reports/Superseded-Module-Report" },
    { "element": "ViewPaymentData", "path": "/Portal/Administration/Reports/View-Payment-Data" },
    { "element": "InvalidUsiReport", "path": "/Portal/Administration/Reports/Invalid-Usi-Report" },
    { "element": "OutstandingInvoicesReport", "path": "/Portal/Administration/Reports/Outstanding-Invoices-Report" },
    { "element": "ModuleKpis", "path": "/Portal/Administration/Reports/Module-Kpis" },
    { "element": "RegisteredInterestReport", "path": "/Portal/Administration/Reports/Registered-Interest-Report" },
    { "element": "ActiveCertificateReport", "path": "/Portal/Administration/Reports/Active-Certificate-Report" },
    { "element": "ReportSettings", "path": "/Portal/Administration/Reports/Report-Settings" },
    { "element": "ModuleReports", "path": "/Portal/Administration/Reports/Module-Reports" },
    { "element": "WhiteCardReport", "path": "/Portal/Administration/Reports/White-Card-Report" },
    { "element": "BackupReport", "path": "/Portal/Administration/Reports/Backup-Report" },
    { "element": "CertificatesUnassignedReport", "path": "/Portal/Administration/Reports/Certificates-Unassigned-Report" },
    { "element": "QiCcos", "path": "/Portal/Administration/Reports/Qi-Ccos" },
    { "element": "BillingReport", "path": "/Portal/Administration/Reports/Billing-Report" },
    { "element": "AvetmissReport", "path": "/Portal/Administration/Reports/Avetmiss-Report" },
    { "element": "EmailReport", "path": "/Portal/Administration/Reports/Email-Report" },
    { "element": "EventReports", "path": "/Portal/Administration/Reports/Event-Reports" },
    { "element": "UsiReport", "path": "/Portal/Administration/Reports/Usi-Report" },
    { "element": "InvoiceReminders", "path": "/Portal/Administration/Reports/Invoice-Reminders" },
    { "element": "TaskGroupEdit", "path": "/Portal/Administration/Setup/Task-Group-Edit" },
    { "element": "Permissions", "path": "/Portal/Administration/Setup/Permissions" },
    { "element": "IntegrationSettings", "path": "/Portal/Administration/Setup/Integration-Settings" },
    { "element": "Branding", "path": "/Portal/Administration/Setup/Branding" },
    { "element": "SearchEventActions", "path": "/Portal/Administration/Setup/Search-Event-Actions" },
    { "element": "EventActionEditor", "path": "/Portal/Administration/Setup/Event-Action-Editor" },
    { "element": "Accounting", "path": "/Portal/Administration/Setup/Accounting" },
    { "element": "CreateFundingPrices", "path": "/Portal/Administration/Setup/Create-Funding-Prices" },
    { "element": "Templates", "path": "/Portal/Administration/Setup/Templates" },
    { "element": "UsiGenerator", "path": "/Portal/Administration/Tools/Usi-Generator" },
    { "element": "StatementFixName", "path": "/Portal/Administration/Tools/Statement-Fix-Name" },
    { "element": "BillingFixReportsTool", "path": "/Portal/Administration/Tools/Billing-Fix-Reports-Tool" },
    { "element": "CreditTransferTool", "path": "/Portal/Administration/Tools/Credit-Transfer-Tool" },
    { "element": "BulkVerifyUsis", "path": "/Portal/Administration/Tools/Bulk-Verify-Usis" },
    { "element": "BulkUploadTas", "path": "/Portal/Administration/Tools/Bulk-Upload-Tas" },
    { "element": "CertificateTransitionTool", "path": "/Portal/Administration/Tools/Certificate-Transition-Tool" },
    { "element": "DevTools", "path": "/Portal/Administration/Tools/Dev-Tools" },
    { "element": "UploadApprovedTrainingSnapshot", "path": "/Portal/Administration/Uploads/Upload-Approved-Training-Snapshot" },
    { "element": "UploadDetPayment", "path": "/Portal/Administration/Uploads/Upload-Det-Payment" },
    { "element": "UploadDetModulesSnapshot", "path": "/Portal/Administration/Uploads/Upload-Det-Modules-Snapshot" },
    { "element": "ScheduleAUploader", "path": "/Portal/Administration/Uploads/Schedule-A-Uploader" },
    { "element": "UploadLegacyAssessment", "path": "/Portal/Administration/Uploads/Upload-Legacy-Assessment" },
    { "element": "UploadDetDataErrorValidation", "path": "/Portal/Administration/Uploads/Upload-Det-Data-Error-Validation" },
    { "element": "UploadNominalHours", "path": "/Portal/Administration/Uploads/Upload-Nominal-Hours" },
    { "element": "UploadUserChoicePriceList", "path": "/Portal/Administration/Uploads/Upload-User-Choice-Price-List" },
    { "element": "UploadAvetmissReport", "path": "/Portal/Administration/Uploads/Upload-Avetmiss-Report" },
    { "element": "UploadAssessment", "path": "/Portal/Administration/Uploads/Upload-Assessment" },
    { "element": "ClassAttendance", "path": "/Portal/CertificatesModules/Class-Attendance" },
    { "element": "Certificates", "path": "/Portal/CertificatesModules/Certificates" },
    { "element": "Modules", "path": "/Portal/CertificatesModules/Modules" },
    { "element": "ModuleVisibilityCurrent", "path": "/Portal/CertificatesModules/Module-Visibility-Current" },
    { "element": "ModuleVisibilityOld", "path": "/Portal/CertificatesModules/Module-Visibility-Old" },
    { "element": "ModuleBilling", "path": "/Portal/CertificatesModules/Module-Billing" },
    { "element": "DocumentRepository", "path": "/Portal/Documentation/Document-Repository" },
    { "element": "PoliciesProcedures", "path": "/Portal/Documentation/Policies-Procedures" },
    { "element": "ComplianceAuditing", "path": "/Portal/Documentation/Compliance-Auditing" },
    { "element": "Marketing", "path": "/Portal/Documentation/Marketing" },
    { "element": "SQEMP", "path": "/Portal/Documentation/S-Q-E-M-P" },
    { "element": "QaModuleReview", "path": "/Portal/Review-Centre/Qa-Module-Review" },
    { "element": "QaReviewList", "path": "/Portal/Review-Centre/Qa-Review-List" },
    { "element": "PendingEventActionSubmissions", "path": "/Portal/Review-Centre/Pending-Event-Action-Submissions" },
    { "element": "LearnerResourceReview", "path": "/Portal/Review-Centre/Learner-Resource-Review" },
    { "element": "PendingTemplateSubmissions", "path": "/Portal/Review-Centre/Pending-Template-Submissions" },
    { "element": "Calender", "path": "/Portal/Calender" },
    { "element": "ClassEdit", "path": "/Portal/Calender/Class-Edit" },
    { "element": "MyClasses", "path": "/Portal/Calender/My-Classes" },
    { "element": "Students", "path": "/Portal/People" },
    { "element": "Human", "path": "/Portal/People" },
    { "element": "Individual", "path": "/Portal/People" },
    { "element": "Citizen", "path": "/Portal/People" },
    { "element": "Folk", "path": "/Portal/People" },
    { "element": "Kin", "path": "/Portal/People" },
    { "element": "Members", "path": "/Portal/People" },
    { "element": "Users", "path": "/Portal/People" },
    { "element": "Participants", "path": "/Portal/People" },
    { "element": "Personnel", "path": "/Portal/People" },
    { "element": "Team", "path": "/Portal/People" },
    { "element": "Companies", "path": "/Portal/Businesses" },
    { "element": "Organizations", "path": "/Portal/Businesses" },
    { "element": "Enterprises", "path": "/Portal/Businesses" },
    { "element": "Firms", "path": "/Portal/Businesses" },
    { "element": "Corporations", "path": "/Portal/Businesses" },
    { "element": "Ventures", "path": "/Portal/Businesses" },
    { "element": "Employer", "path": "/Portal/Businesses" },
    { "element": "Locations", "path": "/Portal/Businesses/Venues-View" },
    { "element": "Facilities", "path": "/Portal/Businesses/Venues-View" },
    { "element": "Places", "path": "/Portal/Businesses/Venues-View" },
    { "element": "Building", "path": "/Portal/Businesses/Venues-View" },
    { "element": "Destination", "path": "/Portal/Businesses/Venues-View" },
    { "element": "Here", "path": "/Portal/Businesses/Venues-View" },
    { "element": "Premises", "path": "/Portal/Businesses/Venues-View" },
    { "element": "Spaces", "path": "/Portal/Businesses/Venues-View" },
    { "element": "FunctionHalls", "path": "/Portal/Businesses/Venues-View" },
    { "element": "Performance", "path": "/Portal/Administration/Reports" },
    { "element": "Trends", "path": "/Portal/Administration/Reports" },
    { "element": "Analysis", "path": "/Portal/Administration/Reports" },
    { "element": "Metrics", "path": "/Portal/Administration/Reports" },
    { "element": "Analytics", "path": "/Portal/Administration/Reports" },
    { "element": "Statistics", "path": "/Portal/Administration/Reports" },
    { "element": "Insights", "path": "/Portal/Administration/Reports" },
    { "element": "DataAnalysis", "path": "/Portal/Administration/Reports" },
    { "element": "Metrics", "path": "/Portal/Administration/Reports" },
    { "element": "Courses", "path": "/Portal/CertificatesModules/Modules" },
    { "element": "Subjects", "path": "/Portal/CertificatesModules/Modules" },
    { "element": "Curriculum", "path": "/Portal/CertificatesModules/Modules" },
    { "element": "Training", "path": "/Portal/CertificatesModules/Modules" },
    { "element": "Learning", "path": "/Portal/CertificatesModules/Modules" },
    { "element": "Programs", "path": "/Portal/CertificatesModules/Modules" },
    { "element": "Curricula", "path": "/Portal/CertificatesModules/Modules" },
    { "element": "Topics", "path": "/Portal/CertificatesModules/Modules" },
    { "element": "Units", "path": "/Portal/CertificatesModules/Modules" },
    { "element": "Courses", "path": "/Portal/CertificatesModules/Modules" },
    { "element": "Diplomas", "path": "/Portal/CertificatesModules/Certificates" },
    { "element": "Credentials", "path": "/Portal/CertificatesModules/Certificates" },
    { "element": "Qualifications", "path": "/Portal/CertificatesModules/Certificates" },
    { "element": "Achievements", "path": "/Portal/CertificatesModules/Certificates" },
    { "element": "Accreditations", "path": "/Portal/CertificatesModules/Certificates" },
    { "element": "Accomplishments", "path": "/Portal/CertificatesModules/Certificates" },
    { "element": "Schedule", "path": "/Portal/Calendar" },
    { "element": "Agenda", "path": "/Portal/Calendar" },
    { "element": "Events", "path": "/Portal/Calendar" },
    { "element": "Planner", "path": "/Portal/Calendar" },
    { "element": "Timetable", "path": "/Portal/Calendar" },
    { "element": "Scheduling", "path": "/Portal/Calendar" },
    { "element": "Itinerary", "path": "/Portal/Calendar" },
    { "element": "Appointments", "path": "/Portal/Calendar" },
    { "element": "Configuration", "path": "/Portal/Administration/Setup" },
    { "element": "Settings", "path": "/Portal/Administration/Setup" },
    { "element": "SetupWizard", "path": "/Portal/Administration/Setup" },
    { "element": "Installation", "path": "/Portal/Administration/Setup" },
    { "element": "Initialization", "path": "/Portal/Administration/Setup" },
    { "element": "Onboarding", "path": "/Portal/Administration/Setup" },
    { "element": "Preparation", "path": "/Portal/Administration/Setup" },
    { "element": "Customization", "path": "/Portal/Administration/Setup" },
    { "element": "Integration", "path": "/Portal/Administration/Setup" },
    { "element": "FileUploads", "path": "/Portal/Administration/Uploads" },
    { "element": "DataImport", "path": "/Portal/Administration/Uploads" },
    { "element": "DocumentUpload", "path": "/Portal/Administration/Uploads" },
    { "element": "MediaUpload", "path": "/Portal/Administration/Uploads" },
    { "element": "ContentUpload", "path": "/Portal/Administration/Uploads" },
    { "element": "DataImport", "path": "/Portal/Administration/Uploads" },
    { "element": "ContentManagement", "path": "/Portal/Administration/Uploads" },
    { "element": "ResourceUpload", "path": "/Portal/Administration/Uploads" },
    { "element": "AssetUpload", "path": "/Portal/Administration/Uploads" },
    { "element": "MediaManagement", "path": "/Portal/Administration/Uploads" },
    { "element": "Assignments", "path": "/Portal/Tasks" },
    { "element": "ToDos", "path": "/Portal/Tasks" },
    { "element": "ActionItems", "path": "/Portal/Tasks" },
    { "element": "WorkItems", "path": "/Portal/Tasks" },
    { "element": "TaskList", "path": "/Portal/Tasks" },
    { "element": "Projects", "path": "/Portal/Tasks" },
    { "element": "Checklists", "path": "/Portal/Tasks" },
    { "element": "Agenda", "path": "/Portal/Tasks" },
    { "element": "Reminders", "path": "/Portal/Tasks" },
    { "element": "Workflow", "path": "/Portal/Tasks" },

];

export default routes;