import React, { useState } from 'react';
import { Row, Col } from "react-bootstrap";
import Upload from "./Dropzone/upload/Upload";

const TabFive = () => {
    const [classId, setClassId] = useState('');
    const [studentList, setStudentList] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [error, setError] = useState('');
    const [file, setFile] = useState(null);

    const handleInputChange = (e) => {
        setClassId(e.target.value);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleFileUpload = async (e) => {
        e.preventDefault();
        if (!file) {
            alert('Please select a file first!');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch('https://api.trainingprofessionals.com.au/calendar/upload', {
                method: 'POST',
                body: formData,
                credentials: 'include'
            });
            const data = await response.json();
            if (!response.ok) throw new Error('Failed to upload file: ' + data.message);
            console.log('File uploaded successfully:', data);
        } catch (error) {
            console.error('Failed to upload file:', error);
        }
    };

    const fetchClassDetails = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://api.trainingprofessionals.com.au/calendar/class', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ classID: classId })
            });

            if (!response.ok) throw new Error('Failed to fetch data');

            const data = await response.json();
            sessionStorage.setItem('classDetails', JSON.stringify(data));
            setStudentList(data.students);
        } catch (error) {
            console.error('Failed to fetch class details:', error);
            setError('Failed to fetch class details');
        }
    };

    const toggleAccordion = (index) => {
        setExpandedRows(prev => ({ ...prev, [index]: !prev[index] }));
    };

    return (
        <div id="Tab 5" className="tabcontent" style={{marginBottom: 250}}>
            <form onSubmit={fetchClassDetails}>
                <Row>
                    <Col xs={6} md={6} xl={6} style={{
                        marginTop: 50,
                        textAlign: 'left',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-center',
                        alignItems: 'flex-center'
                    }}>
                        <input
                            type="text"
                            name="Class details"
                            value={classId}
                            placeholder="Enter the class ID here"
                            onChange={handleInputChange}
                            title="This is to ensure that only the students and modules for the class are loaded"
                        />
                        <button style={{ width: '200px', marginTop: 20 }} type="submit">Fetch Class Details</button>
                    </Col>
                </Row>
            </form>

            {error && <p>{error}</p>}

            <table>
                <thead>
                <tr className={'tr2'}>
                    <th>Student</th>
                    {/*<th>Mobile</th>*/}
                    {/*<th>Billing</th>*/}
                    {/*<th>USI</th>*/}
                    {/*<th>Competent</th>*/}
                    {/*<th>Actions</th>*/}
                </tr>
                </thead>
                <tbody>
                {studentList.map((student, index) => (
                    <React.Fragment key={index}>
                        <tr onClick={() => toggleAccordion(index)} key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>
                            <td>{student.name}</td>
                            {/*<td>{student.mobile}</td>*/}
                            {/*<td>{student.billing}</td>*/}
                            {/*<td>{student.usi}</td>*/}
                            {/*<td>{student.competent}</td>*/}
                            {/*<td>{student.actions}</td>*/}
                        </tr>
                        {expandedRows[index] && (
                            <tr>
                                <td colSpan={12} style={{ paddingTop: 0, padding: 0 }}>
                                    <table style={{ paddingTop: 5, border: 0, width: '100%', paddingLeft: 0, paddingRight: 0 }} key={index} className={index % 2 === 0 ? 'dark-grey' : 'light-grey'}>
                                        <thead className={'tr2'}>
                                        <tr className={'tr2'}>
                                            <th style={{ paddingLeft: 100, width: '20%' }}>Code</th>
                                            <th style={{ textAlign: 'left', paddingLeft: 50, width: '40%' }}>Name</th>
                                            <th style={{ textAlign: 'left', paddingLeft: 50, width: '40%' }}>Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {student.modules.map((module, moduleIndex) => (
                                            <tr key={moduleIndex} style={{ paddingTop: 2, paddingBottom: 2 }}>
                                                <td style={{ paddingLeft: 100, width: '20%' }}>{module.code}</td>
                                                <td style={{ textAlign: 'left', paddingLeft: 50, width: '40%' }}>{module.name}</td>
                                                <td style={{ textAlign: 'left', paddingLeft: 50, width: '40%' }}>{module.status}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        )}
                    </React.Fragment>
                ))}
                </tbody>
            </table>

            <Upload />
        </div>
    );
};

export default TabFive;
